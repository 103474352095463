<template>
  <v-dialog persistent v-model="flag" scrollable>
    <v-card
        class="mx-auto"
        max-width="500"
        id="cardImage"
    >
      <v-card-text class="mt-4">
        <div class="text-h6 font-weight-bold">결제금액</div>
        <p class="text-h3 text--primary pt-2">
          {{payInfo.itTotalAmt| currency('원')}}
        </p>
        <div class="mt-10 text-right">{{payInfo.issueCmpnyNm}}</div>
        <v-divider class="font-weight-bold mb-4"></v-divider>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-credit-card-check-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>카드번호</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.paymentNo}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>승인일시</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.dateStr}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action></v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>승인번호</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.confmNo}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action></v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>영수증번호</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.rciptNo}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action></v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>결제완료</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.payFinishYn}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action></v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>할부</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.instlmtMonth}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-currency-krw
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>공급가액</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.splpc| currency('원')}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo"></v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>부가세</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.vat| currency('원')}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-shopping
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>판매자</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.mberName}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>사업자번호</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.bizNum}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>연락처</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>{{payInfo.mberMobile}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-basket-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>결제사</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>유니코아</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>사업자번호</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>820-81-00121</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon></v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>연락처</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-subtitle>02-6442-6537</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn id="hiddenbtn" block outlined color="amber darken-4" class="font-weight-black" @click="closeEvent">
          닫기 <v-icon class="ml-2">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "cardResult",
  props : [
    'flag' , 'payInfo'
  ],
  methods: {
    closeEvent(){
      this.$emit('closeEvent')
    },
    imageDown(){
      let pdfImg = {
        target: 'body',
        captureHiddenClass: 'vti__hidden',
        captureShowClass: 'vti__show',
        captureActiveClass: 'vti__active',
        fileName: '비즈페이결제정보',
        fileType: 'png',
        returnAction: 'download', // blob, base64, canvas, clipboard, newWindow
        callback: (img) => { return img } // modifies what image is returned
      }
      vue2img().image(pdfImg)
    }
  },
}
</script>

<style scoped>

</style>