import api from "../api/smsLink";

const smsLinkStore = {
    namespaced: true,
    state : {
        smsGoodsInfo : {},//sms 상품정보
        linkGoodsInfo : [], // link 상품정보 - 배열이다 조심
        smsCardPaymentInfo : {}, // 카드 결제 정보
        smsPayResultInfo : {
            finishYn : 'N',
            goodsList : [{
                itName: "삼품명",
                itDetailUrl: "http://url.com",
                itPrice: 1000,
                itCount: 1,
                itAddInfo: "상품명추가설명"
            }],
            sellerInfo : {
                mberCode: "2011020004",
                mberName: "유니코아",
                mberMobile: "0101234567",
                companyName: "유니코아",
                installmentMonths: 6,
                sugiCertification: "N"
            },
            destination : {
                mberCode: 0,
                rciptNo: 0,
                recipient: "받는사람",
                mobilePhone: "1600-0174",
                address: "서울특별시 구로구 디지털로34길 43 코오롱싸이언스밸리1차 1108호",
                message: null
            },
            paymentInfo : {
                type : "card",
                dateStr : "2021-07-28 18:27:58",
                name : "현대카드",
                paymentNo : "1111-33**-****-0000",
                rciptNo : 2107280002
            }
        } // sms 결제완료후 정보
    },
    getters : {
        SMS_PAY_RESULT_INFO : state =>{
            return state.smsPayResultInfo;
        },
        SMS_GOOD_INFO : state => {
            return state.smsGoodsInfo
        },
        LINK_GOOD_INFO : state => {
            return state.linkGoodsInfo
        },
        CARD_PAYMENT_INFO : state => state.smsCardPaymentInfo
    },
    mutations : {
        set_sms_goods_info(state , info){
            state.smsGoodsInfo = info;
        },
        set_link_goods_info(state , info){
            state.linkGoodsInfo = info;
        },
        set_card_payment_info(state , info){
            state.smsCardPaymentInfo = info;
        },
        set_sms_payment_result_info(state , map){
            state.smsPayResultInfo = map;
        },
    },
    actions : {
        // 결제 결과 정보 조회
        async fetch_sms_payment_result({commit} , id){
          try {
              const map = await api.smsPaymentResult(id);
              await commit('set_sms_payment_result_info' , map);

          }  catch (e) {
              console.error(e)
              commit('SET_ALERT_DIALOG',{ flag : true , message : '정보 조회에 문제 있습니다.' , title : error.type , type : 'error'}, { root: true });
          }
        },
        // 카드결제정보 획득
        async fetch_card_payment_info({commit} , id){
            try {
                const info = await api.smsCardPaymentInfo(id);
                await commit('set_card_payment_info' , info);
            } catch (error) {
                console.error(error);
                commit('SET_ALERT_DIALOG',{ flag : true , message : '결제정보에 문제가 있습니다.' , title : error.type , type : 'error'}, { root: true });
            }
        },
        // sms 상품정보 가져오기
        async fetch_sms_good_info({commit} , id){
            try {
                const info = await api.smsGoodsInfo(id); // sms 결제에 저장된 id 를 가지고 온다.
                await commit('set_sms_goods_info' ,info);
                return info;
            } catch (error) {
                console.error(error);
                return Promise.reject(error);

            }
        },
        // sms 결제진행
        async request_sms_payment({commit} , payload){
            try {
                await api.smsPaymentRequest(payload)
                await commit('set_sms_goods_info' , {});
                return Promise.resolve(1);
            }catch (e) {
                console.error(e);
                commit('SET_ALERT_DIALOG',{ flag : true , message : e.message , title : e.type , type : 'error'}, { root: true });
                return Promise.reject(e);
            }
        },
        // link 상품정보 가져오기
        async fetch_link_goods_info({commit} , id){
            try {
                const list = await api.linkGoodsInfo(id);
                // 기본 1개 선택으로 변경하려고함
                const settingList = await list.map(item =>{
                    item.itemCount = 1;
                    item.itemAmt = item.itPrice;
                    return item;
                })

                await commit('set_link_goods_info' ,settingList);
                return settingList;
            } catch (error) {
                console.error(error);
                commit('SET_ALERT_DIALOG',{ flag : true , message : error.message , title : error.type , type : 'error'}, { root: true });
            }
        },

        // link 결제및 sms 상품 정보 저장 -- sms 상품정보 저장은 link 결제와 함께 일괄적으로 처리되어 하나로 만듬
        async request_link_payment({commit} , {smsInfo , linkPayment}){
            try {
                const smsId = await api.insertSmsGoods(smsInfo);
                if(smsId){
                    linkPayment.id = smsId;
                    await api.smsPaymentRequest(linkPayment);
                    await commit('set_link_goods_info' , {});
                    return smsId;
                }
            } catch (error) {
                commit('SET_ALERT_DIALOG',{ flag : true , message : error.message , title : error.type , type : 'error'}, { root: true });
                return false
            }
        }
    }
}
export default smsLinkStore;
