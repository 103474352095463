<!--참고한부분: https://codepen.io/quinlo/pen/YONMEa  -->
<template>
    <div>
        <div class="vue-credit-card" >
            <div class="card-form-and-image">
                <div class="credit-card-image" >
                    <div class="creditcard">
                        <Front :cardNo="this.cardNo"
                            :expiration="this.expiration"
                            color="orange">     
                        </Front>
                    </div>
                </div>            
                <div class="credit-card-form">
                    <div class="field">
                        <label for="card-number">카드번호</label>
                        <label class="labelsalert"> *필수값</label>
                        <input type="text"
                            v-model="cardNo"
                            v-mask="'#### #### #### ####'"
                            inputmode="numeric"
                            placeholder="카드번호를 입력하세요"
                        >
                    </div>
                    <div class="field-group">
                        <div class="field">
                            <label>카드유효년월</label>
                            <label class="labelsalert"> *필수값</label>
                            <input type="text"
                                v-model="expiration"
                                v-mask="'##/##'"
                                placeholder='MM/YY'
                                inputmode="numeric"
                            >
                        </div>
                        <div class="field">
                            <label>할부(5만원이상)</label>
                            <select :disabled="halbuflag" v-model="installment">
                                <option value="00" v-if="this.paydata.installment >= 0">일시불</option>
                                <option value="02" v-if="this.paydata.installment >= 2">2</option>
                                <option value="03" v-if="this.paydata.installment >= 3"> 3</option>
                                <option value="04" v-if="this.paydata.installment >= 4">4</option>
                                <option value="05" v-if="this.paydata.installment >= 5">5</option>
                                <option value="06" v-if="this.paydata.installment >= 6">6</option>
                                <option value="07" v-if="this.paydata.installment >= 7">7</option>
                                <option value="08" v-if="this.paydata.installment >= 8">8</option>
                                <option value="09" v-if="this.paydata.installment >= 9">9</option>
                                <option value="10" v-if="this.paydata.installment >= 10">10</option>
                                <option value="11" v-if="this.paydata.installment >= 11">11</option>
                                <option value="12" v-if="this.paydata.installment >= 12">12</option>
                            </select>
                        </div>
                        <!--
                        cvc 입력창
                        <div class="field">
                            <label for="securitycode">cvs</label>
                            <input type="text"
                                name="security_code"
                                id="securitycode"
                                ref="security"
                                pattern="[0-9]*"
                                placeholder="trans.security.placeholder"
                                inputmode="numeric"
                                @focus="flipped = true">
                        </div> -->
                    </div>
                    
                    <div class="field" v-if="sugiCertification">
                        <label for="birthtext">본인확인</label>
                        <input type="text"
                            v-model="pidNum"
                            v-mask="'###########'"
                            placeholder='생년월일/법인-사업자번호10자리'
                            inputmode="numeric"
                        >
                    </div>
                    <div class="field" v-if="sugiCertification">
                        <label for="passinput">비밀번호</label>
                        <input type="text"
                            v-model ="passwd"
                            v-mask="'##**'"
                            placeholder='앞두자리'
                            inputmode="numeric"
                        >
                    </div>
                    <div class="field">
                        <label>이메일</label>
                        <input type="text"
                            v-model="email"
                            placeholder='이메일'
                            inputmode="email"
                        >
                    </div>
                    <div class="field">
                        <label >핸드폰번호</label>
                        <input type="text"
                            placeholder='연락받을 핸드폰번호'
                            inputmode="numeric"
                            v-mask="'01#-####-####'"
                            v-model="phoneNumber"
                        >
                    </div>
                </div>
            </div>
            <v-row 
                class="mr-2 ml-2" 
            >
                <v-col>
                    <v-btn 
                        color="blue darken-3" 
                        block
                        dark 
                        :disabled="disabled"
                        @click="payment"
                        >
                        결제하기
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn 
                        color="orange darken-4" 
                        block
                        dark 

                        @click="cancelEvent"
                        >
                        취소하기
                    </v-btn>
                </v-col>
                
            </v-row>
        </div>
    </div>
</template>

<script>
import {Front} from './cards';
import { mapMutations , mapActions} from 'vuex';
export default {
    props: {
        paydata : {
            type : Object
        },
        sugiCertification : {
            type : Boolean
        },
    },

    components: {
        Front,
    },

    computed: {
        halbuflag(){
            if(!this.paydata) return true;
            if(this.paydata.price >= 50000 && this.paydata.installment > 0) return false;
            else return true;
        },
        disabled(){
            if(!this.cardNo || !this.expiration ){
                return true;
            }else {
                if(this.cardNo.length < 19){
                    return true;
                }
                // if(this.passwd.length < 2){
                //     return true;
                // }
                // if(this.pidNum.length < 6){
                //     return true;
                // }
                // if(this.phoneNumber.length < 13){
                //     return true;
                // }
                if(this.expiration.length < 5){
                    return true;
                }
                // if(this.email.length >0){
                //     return ((/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) ) ? false : true );
                // }
                return false;
            }
        }
    },

    data() {
        return {
            cardNo : '',
            passwd : '',
            pidNum : '',
            phoneNumber : '',
            installment : '00',
            email : '',
            expiration : '',
        };
    },
    methods: {
        ...mapMutations(['SET_ALERT_DIALOG']),
        ...mapActions('qrStore' , ['fetch_qr_pay_request','fetch_qr_pay_cancel']),
        cancelEvent(){   
            let payload = { 
                exorderNo : this.$store.state.qrStore.goods.exorderNo,
                memberId: this.$store.state.qrStore.goods.mberId,
                status : 9000
            }
            this.fetch_qr_pay_cancel(payload)
            .then( _=>{
                this.$emit('payClear' , '9000');
                }    
            ).catch(error=>{
                console.error(error);
                // 유니코아쪽에 결제사로 전달 안된 상황이므로 에러 상황이여도 취소 보내자
                this.$emit('payClear' , '9000');
            })




        },
        payment(){
            this.$emit('loadingEvent' , '결제 처리중');
            let payload = {
                cardNo : this.cardNo.replace(/ /g,""),
                passwd : this.passwd,
                pidNum : this.pidNum,
                phoneNumber : this.phoneNumber,
                email : this.email,
                installment : this.installment,
                expiration : this.expiration.substr(3,2) + this.expiration.substr(0,2),
                amount : this.$store.state.qrStore.goods.orderPrice,
                orderName : this.$store.state.qrStore.goods.orderName,
                exorderNo : this.$store.state.qrStore.goods.exorderNo,
                memberId: this.$store.state.qrStore.goods.mberId,
            }

            this.fetch_qr_pay_request(payload)
            .then(_=>{
                this.$emit('payClear' , '1000');
            })
            .catch(err =>{
                console.error(err);
                if(!!err.type) this.$emit('payClear' , err.type);
                else this.$emit('payClear' , '9999');
            });            
        }
    },    
}
</script>

<style lang="scss" >
.vue-credit-card {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;

    max-width: 800px;
    margin: 0 auto;
    .card-form-and-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .credit-card-form {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            max-width: 400px;
            padding: 20px;
            color: #707070;
            .field-group {
                display: flex;
                .field:first-child {
                    margin-right: 10px;
                }
            }
            .field {
                position: relative;
                width: 100%;
                margin: 10px 0;
                label {
                    padding-bottom: 5px;
                    font-size: 13px;
                }
                input {
                    box-sizing: border-box;
                    margin-top: 3px;
                    padding: 15px;
                    font-size: 16px;
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid #dcdcdc;
                }
                select{
                  -webkit-appearance: none;  /* 네이티브 외형 감추기 */
                  -moz-appearance: none;
                  appearance: none;
                  background: url('/arrow-down.png') no-repeat 95% 50%;  /* 화살표 모양의 이미지 */


                   box-sizing: border-box;
                    margin-top: 3px;
                    padding: 15px;
                    font-size: 16px;
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid #dcdcdc;
                    
                }
            }
        }
    }
    .ccicon {
        height: 38px;
        position: absolute;
        right: 6px;
        top: calc(50% - 9px);
        width: 60px;
    }
    .credit-card-image {
        width: 100%;
        max-width: 400px;
        max-height: 251px;
        height: 54vw;
        padding: 20px;
        perspective: 1000px;
    }
    #ccsingle {
        position: absolute;
        right: 15px;
        top: 20px;
        svg {
            width: 100px;
            max-height: 60px;
        }
    }
    .creditcard {
        width: 100%;
        max-width: 400px;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        cursor: pointer;
        .front,
        .back {
            position: absolute;
            width: 100%;
            max-width: 400px;
            backface-visibility: hidden;
            color: #47525d;
        }
        .back {
            transform: rotateY(180deg);
        }
        &.flipped {
            transform: rotateY(180deg);
        }
        svg#cardfront,
        svg#cardback {
            width: 100%;
            box-shadow: 1px 5px 6px 0px black;
            border-radius: 22px;
        }
        .lightcolor,
        .darkcolor {
            transition: fill .5s;
        }
        .lightblue {
            fill: #03A9F4;
        }
        .lightbluedark {
            fill: #0288D1;
        }
        .red {
            fill: #ef5350;
        }
        .reddark {
            fill: #d32f2f;
        }
        .purple {
            fill: #ab47bc;
        }
        .purpledark {
            fill: #7b1fa2;
        }
        .cyan {
            fill: #26c6da;
        }
        .cyandark {
            fill: #0097a7;
        }
        .green {
            fill: #66bb6a;
        }
        .greendark {
            fill: #388e3c;
        }
        .lime {
            fill: #d4e157;
        }
        .limedark {
            fill: #afb42b;
        }
        .yellow {
            fill: #ffeb3b;
        }
        .yellowdark {
            fill: #f9a825;
        }
        .orange {
            fill: #ff9800;
        }
        .orangedark {
            fill: #ef6c00;
        }
        .grey {
            fill: #bdbdbd;
        }
        .greydark {
            fill: #616161;
        }
    }
    #svgname {
        text-transform: uppercase;
    }
    #cardfront {
        .st2 {
            fill: #FFFFFF;
        }
        .st3 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 600;
        }
        .st4 {
            font-size: 54.7817px;
        }
        .st5 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 400;
        }
        .st6 {
            font-size: 33.1112px;
        }
        .st7 {
            opacity: 0.6;
            fill: #FFFFFF;
        }
        .st8 {
            font-size: 24px;
        }
        .st9 {
            font-size: 36.5498px;
        }
        .st10 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 300;
        }
        .st11 {
            font-size: 16.1716px;
        }
        .st12 {
            fill: #4C4C4C;
        }
    }
    #cardback {
        .st0 {
            fill: none;
            stroke: #0F0F0F;
            stroke-miterlimit: 10;
        }
        .st2 {
            fill: #111111;
        }
        .st3 {
            fill: #F2F2F2;
        }
        .st4 {
            fill: #D8D2DB;
        }
        .st5 {
            fill: #C4C4C4;
        }
        .st6 {
            font-family: 'Source Code Pro', monospace;
            font-weight: 400;
        }
        .st7 {
            font-size: 27px;
        }
        .st8 {
            opacity: 0.6;
        }
        .st9 {
            fill: #FFFFFF;
        }
        .st10 {
            font-size: 24px;
        }
        .st11 {
            fill: #EAEAEA;
        }
        .st12 {
            font-family: 'Rock Salt', cursive;
        }
        .st13 {
            font-size: 37.769px;
        }
    }

    .labelsalert {
        font-weight: bolder !important;
        font-size: 8px !important;
        color: red !important;
    }

    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: rgba(73, 30, 37, 0.39) !important;
    }
}
</style>