<template>
  <v-container>
    <v-form
        ref="form"
        v-model="valid"
    >
      <v-text-field
          v-model="nextUrl"

          :rules="nameRules"
          label="nextUrl"
          required
      ></v-text-field>
      <v-text-field
          v-model="notiUrl"

          :rules="nameRules"
          label="notiUrl"
          required
      ></v-text-field>

      <v-text-field
          v-model="orderName"

          :rules="nameRules"
          label="주문상품명"
          required
      ></v-text-field>

      <v-text-field
          v-model="orderPrice"
          :rules="numberRules"
          label="요청금액"
          required
      ></v-text-field>
      <v-text-field
          v-model="exorderNo"
          :rules="nameRules"
          label="qr주문번호"
          required
      ></v-text-field>
      <v-text-field
          v-model="bigo"
          label="남기는 메세지-bigo"
      ></v-text-field>
      <v-text-field
          v-model="pkHash"
          :counter="200"
          required
          label="키값"
      ></v-text-field>

      <v-text-field
          v-model="mberId"
          label="요청아이디"
          required
      ></v-text-field>

      <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="payCall"
      >
        결제하기
      </v-btn>

      <v-btn
          color="error"
          class="mr-4"
          @click="reset"
      >
        리셋하기
      </v-btn>

      <v-btn
          color="warning"
          class="mr-4"
          @click="resetValidation"
      >
        경고 무시하기
      </v-btn>

      <v-btn
          color="error"
          class="mr-4"
          @click="hashMake"
      >
        해시키생성
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import sha256 from 'crypto-js/sha256';
export default {
  name: "QrPayCall",
  data: () => ({
    nextUrl : "https://admin.uni-core.co.kr",
    notiUrl : "https://admin.uni-core.co.kr",
    pkHash : null,
    orderName : '',
    orderPrice : null,
    exorderNo : null,
    mberId : 'B0002A4074',
    bigo : '',
    valid: false,
    name: '',
    nameRules: [
      v => !!v || '필수값입니다.',
    ],
    numberRules: [
      v => !!v || '필수값입니다.',
      v =>  /^[0-9]*$/.test(v) || '숫자만 입력하세요.',
      v =>  101 < v || '최소결제금액은 101원입니다.',
    ],
  }),

  methods: {
    payCall(){
      let goForm = document.createElement("form");
      goForm.setAttribute('charset' , 'UTF-8');
      goForm.setAttribute('method' , 'post');
      goForm.setAttribute('action' , 'https://api.uni-core.co.kr/qrPay');

      let nextUrlEl = document.createElement("input");
      nextUrlEl.setAttribute('type' , 'hidden');
      nextUrlEl.setAttribute('name' , 'nextUrl');
      nextUrlEl.setAttribute('value' , this.nextUrl  );
      goForm.appendChild(nextUrlEl)

      let pkHashEl = document.createElement("input");
      pkHashEl.setAttribute('type' , 'hidden');
      pkHashEl.setAttribute('name' , 'pkHash');
      pkHashEl.setAttribute('value' , this.pkHash  );
      goForm.appendChild(pkHashEl)

      let notiUrlEl = document.createElement("input");
      notiUrlEl.setAttribute('type' , 'hidden');
      notiUrlEl.setAttribute('name' , 'notiUrl');
      notiUrlEl.setAttribute('value' , this.notiUrl  );
      goForm.appendChild(notiUrlEl)

      let orderNameEl = document.createElement("input");
      orderNameEl.setAttribute('type' , 'hidden');
      orderNameEl.setAttribute('name' , 'orderName');
      orderNameEl.setAttribute('value' , this.orderName  );
      goForm.appendChild(orderNameEl)

      let orderPriceEl = document.createElement("input");
      orderPriceEl.setAttribute('type' , 'hidden');
      orderPriceEl.setAttribute('name' , 'orderPrice');
      orderPriceEl.setAttribute('value' , this.orderPrice  );
      goForm.appendChild(orderPriceEl)

      let exorderNoEl = document.createElement("input");
      exorderNoEl.setAttribute('type' , 'hidden');
      exorderNoEl.setAttribute('name' , 'exorderNo');
      exorderNoEl.setAttribute('value' , this.exorderNo  );
      goForm.appendChild(exorderNoEl)

      let mberIdEl = document.createElement("input");
      mberIdEl.setAttribute('type' , 'hidden');
      mberIdEl.setAttribute('name' , 'mberId');
      mberIdEl.setAttribute('value' , this.mberId  );
      goForm.appendChild(mberIdEl)

      let bigoEl = document.createElement("input");
      bigoEl.setAttribute('type' , 'hidden');
      bigoEl.setAttribute('name' , 'bigo');
      bigoEl.setAttribute('value' , this.bigo  );
      goForm.appendChild(bigoEl)

      document.body.appendChild(goForm);

      goForm.submit();
    },

    // 해시키 생성및 확인
    hashMake(){
      this.pkHash = sha256(this.mberId +this.orderName+this.orderPrice+this.exorderNo+'unicore').toString();
    },

    reset () {
      this.nextUrl = '';
      this.notiUrl = '';
      this.pkHash = '';
      this.orderName = '';
      this.orderPrice = '';
      this.exorderNo = '';
      this.mberId = 'B0002A1777';
      this.valid = false;
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style scoped>

</style>