import Vue from 'vue';
import VueRouter from 'vue-router';
import QrPayment from "@/views/QrPayment";
import QrPayCall from "@/views/QrPayCall";
import SmsPay from "@/views/SmsPay";
import Linkpay from "@/views/LinkPay";
import Page404 from "@/views/Page404.vue";
import SmsPayResult from "@/views/SmsPayResult";
import SmsPayN from "@/views/SmsPayN";
import SmsSalesSlip from "@/views/SmsSalesSlip";

import ExternalLayout from "@/components/layout/ExternalLayout";

Vue.use(VueRouter);

const routes = [
    {
        path: '/external',
        name: 'ExterPage',
        component: ExternalLayout,
        children: [
            {
                path: 'qrpa/:orderNO',
                name: 'externalQrpa',
                component: QrPayment,
            },
            {
                path: 'smspayn',
                name: 'externalSmspayn',
                component: SmsPayN,
            },
            {
                path: 'qrExCall',
                name: 'qrExCall',
                component: QrPayCall,
            },
            {
                path: 'smspay/:id',
                name: 'smspay',
                component: SmsPay,
            },
            {
                path: 'linkpay/:id',
                name: 'linkpay',
                component: Linkpay,
            },{
                path: 'smsPayResult/:id',
                name: 'SmsPayResult',
                component: SmsPayResult,
            }
        ]
    },
    {
        path: '/SmsSalesSlip/:id',
        name: 'SmsSalesSlip',
        component: SmsSalesSlip,
    },

    // {
    //     path: '/external/qrpa/:orderNO',
    //     name: 'externalMain',
    //     component: QrPayment,
    // },
    // {
    //     path: '/external/smspayn',
    //     name: 'externalMain',
    //     component: SmsPayN,
    // },
    // {
    //     path: '/external/qrExCall',
    //     name: 'qrExCall',
    //     component: QrPayCall,
    // },
    // {
    //     path: '/external/smspay/:id',
    //     name: 'smspay',
    //     component: SmsPay,
    // },
    // {
    //     path: '/external/linkpay/:id',
    //     name: 'linkpay',
    //     component: Linkpay,
    // },{
    //     path: '/external/smsPayResult/:id',
    //     name: 'SmsPayResult',
    //     component: SmsPayResult,
    // }, {
    //     path: '/external/SmsSalesSlip',
    //     name: 'SmsSalesSlip',
    //     component: SmsSalesSlip,
    // },
    {
        path: '*',
        name: 'Page404',
        component: Page404,
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;
