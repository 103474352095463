// 금액 출금등처리 마이너스표스 및 3자리 금액표시
const currency = (val , gubun)=>{
    if (!val) return 0;
    let minus = '';
    if(val < 0) {
        minus = '-';
        val = val*-1;
    }
    if(typeof(val)==='string'){
        val = val.replace(/,/g,"");
    }
    if(/[^0-9]/g.test(val)){
        return val
    }
    val = Number(val);
    val = val.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    if(!!gubun){
        if(gubun==='출금')val = "-"+val;
        else if(gubun==='출금원')val = "-"+val+" 원";
        else val = val + ' ' + gubun;
    }

    if(minus==='-')val = "-"+val;

    return val;
}

// , 값제거
const commaReplace = (val) =>{
    if(typeof(val)=='string'){
        val = val.replace(/,/g,"");
    }
    return val;
}

// 정산 구분코드
const calculateType = val =>{
    if(!val) return "";
    if(val==='U') return '유니코아'
    else if(val==='u') return '유니코아'
    else return val;
}

// 핸드폰 형태 변경 010-1234-1234
const handPhoneNum = val =>{
    //debugger
    if(!val) return "";
    if(val && val.length === 11){
        let rtnNum;
        let regExp =/(010)([0-9]{3,4})([0-9]{4})$/;
        let myArray;
        if(regExp.test(val)){
            myArray = regExp.exec(val);
            rtnNum = myArray[1]+'-' + myArray[2]+'-'+myArray[3]; return rtnNum;
        } else {
            regExp =/(0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
            if(regExp.test(val)){
                myArray = regExp.exec(val);
                rtnNum = myArray[1]+'-'+myArray[2]+'-'+myArray[3];
                return rtnNum;
            } else {
                return val;
            }
        }
    }else return val
}

// 일반 전화 형태변경 02-1234-2541
const phoneNum = val =>{
    if(!val) return "";
    if(val && val.length === 11){
        let rtnNum;
        let regExp =/(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
        let myArray;
        if(regExp.test(val)){
            myArray = regExp.exec(val);
            rtnNum = myArray[1]+'-'+myArray[2]+'-'+myArray[3];
            return rtnNum;
        }else { return val; }
    }else return val
}

// getDeviceName 결제방법 한글명칭 변경
const getDeviceName = val =>{
    let retStr = "";
    if(!val) return retStr;
    switch (val) {
        case 'SUGI_NORMAL':
            retStr = "수기_비인증"
            break;
        case 'SUGI_CERTIFICATION':
            retStr = "수기_인증"
            break;
        case '500':
            retStr = "SC500"
            break;
        case '500B':
            retStr = "SC500B"
            break;
        case '7201':
            retStr = "다날(LC7201)"
            break;
        case '7602':
            retStr = "다날(LC7602)"
            break;
        case '7203':
            retStr = "다날(LC7203)"
            break;
        case '7211':
            retStr = "다날(LC7211)"
            break;
        case 'DANAL':
            retStr = "다날"
            break;
        default:
            retStr = val;
            break;
    }
    return retStr;
}

// 사업자번호 / 주민등록번호 유형변경
const bizNum = val =>{
    if(!val) return val;
    val = val.replace(/-/g,"");
    if(val.length == 10){
        return val.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    }
    if(val.length == 13){
        return val.replace(/(\d{6})(\d{7})/, '$1-$2');
    }
    return val;
}

// - 등 특수 문자 제거 obj - 오브젝트 , nameArr : string[] :  -오브젝트내 변경할 속성이름
// 새로운 객체 반환
const removeCharObject = (obj , nameArr) =>{
    if (typeof obj === 'object' && obj !== null) {

        let cloneObj = JSON.parse(JSON.stringify(obj));
        nameArr.forEach(el => {
            if(!!cloneObj[el] &&  typeof(cloneObj[el])=='string') {

                cloneObj[el] = cloneObj[el].replace(/-/g,"");
                cloneObj[el] = cloneObj[el].replace(/,/g,"");
            }
        });
        return cloneObj
    }else return obj;
}

// 문자열 길이 구한기
const getTextLength = function(str) {
    let len = 0;
    if(!str) return len;
    if(typeof str !== 'string') str = String(str);
    for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length == 6) {
            len++;
        }
        len++;
    }
    return len;
}

const urlCheck = (strUrl) =>{
    const expUrl = /^http[s]?\:\/\//i;
    return expUrl.test(strUrl);   
}

export {
    currency,
    calculateType,
    getDeviceName,
    handPhoneNum,
    phoneNum,
    bizNum,
    removeCharObject,
    commaReplace,
    getTextLength,
    urlCheck
}