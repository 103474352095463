<template>
  <v-card outlined color="grey lighten-3">
    <v-toolbar dark dense flat color="pink darken-1" class="font-weight-black">
      <v-icon color="indigo lighten-5" class="mr-3">mdi-gift-open-outline</v-icon>
      <v-toolbar-title>
        상품 상세
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
           <tr>
             <th class="text-left">
               상품명
             </th>
             <th class="text-left">
               설명
             </th>
             <th class="text-left">
               수량
             </th>
             <th class="text-left">
               가격
             </th>
             <th class="text-left">
               제품정보
             </th>
            </tr> 
          </thead>
          <tbody>
            <tr v-for="(item , index) in list" :key="index">
              <td>{{ item.itName }}</td>
              <td>{{ item.itAddInfo }}</td>
              <td>{{ item.itCount }}</td>
              <td>{{item.itPrice | currency('원')}}</td>
              <td>
                <v-btn dark fab x-small color="pink lighten-2" class="font-weight-black" @click="detailUrlEvent(item.itDetailUrl)">
                  <v-icon>mdi-search-web</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-right font-weight-black"> 총 결제금액 : {{ amount | currency('원') }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: "OrderInfo",
  props : {
    list : Array,
    amount : String

  },
  methods: {
    ...mapMutations(['SET_ALERT_DIALOG']),
    detailUrlEvent(url){
      if(url){
        window.open(url , "_blank");
      }else {
        this.SET_ALERT_DIALOG({flag : true , message : '상품페이지가 준비되지 않았습니다.' , type : 'error' });
      }
    }
  },
}
</script>

<style scoped>

</style>