<template>
  <v-container >
    <v-card outlined>
      <v-list-item>
        <span class="text-h6 font-weight-bold">판매자명</span>
      </v-list-item>
      <v-list class="transparent">
        <v-list-item>
          <v-list-item-title>연락처</v-list-item-title>
          <v-list-item-content class="text-right">
        
          <v-btn
          class="text--primary font-weight-black"
          text
          v
        >
            010-9999-6666
        </v-btn>
        </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-title>
        <span class="text-h6 font-weight-bold">판매자정보</span>
      </v-card-title>
      <v-divider class="ml-2 mr-2"></v-divider>
      <v-card-text>
        <div class="body-2">
          상품설명 들어간다.
        </div>
        <p class="text-h6 text--primary font-weight-black">상품명</p>
        <div class="text--primary font-weight-black">4,8000 원</div>
        <div class="text--primary">주문수량 : 1개</div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-4 text--primary font-weight-black"
          outlined
          small
        >
          상품정보확인
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card outlined>
      <v-card-title>
        <span class="text-h6 font-weight-bold">상품정보</span>
      </v-card-title>
      <v-divider class="ml-2 mr-2"></v-divider>
      <v-card-text>
        <div class="body-2">
          상품설명 들어간다.
        </div>
        <p class="text-h6 text--primary font-weight-black">상품명</p>
        <div class="text--primary font-weight-black">4,8000 원</div>
        <div class="text--primary">주문수량 : 1개</div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-4 text--primary font-weight-black"
          outlined
          small
        >
          상품정보확인
        </v-btn>
      </v-card-actions>
    </v-card>
    

    
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>