<template>
  <v-dialog
      persistent
      v-model="dialogFlag"
      width="450"
      height="300"
      hide-overlay
  >
    <v-card max-width="450" min-width="320">
      <v-system-bar
          window
          dark
          color="yellow darken-4"
      >
        <v-icon>mdi-gesture-tap</v-icon>
        <span class="font-weight-black">카드 수기결제</span>

        <v-spacer></v-spacer>
        <v-icon @click="closeEvent" >mdi-close</v-icon>
      </v-system-bar>

      <v-card color="">

        <v-card-text class="brown lighten-5">
          <v-list-item two-line class="gray">
            <v-list-item-content>
              <v-list-item-subtitle>결제금액</v-list-item-subtitle>
              <v-list-item-title class="text-h4 font-weight-black" >
                 {{price | currency}}원
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>할부기간</v-list-item-subtitle>


            </v-list-item-content>
            <v-list-item-content  class="text-right">
              <div  v-if="sugiCertification=='Y'">

                할부가능 최대 {{this.installmentMonths}} 개월
              </div>
              <div v-else>
                일시불(할부 불가능)
              </div>
            </v-list-item-content>
          </v-list-item>

          </v-card-text>
          <v-card-text>

          <v-row no-gutters class="mt-2 mb-2">
            <v-col cols="12">
              <div>카드번호</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3" class="pl-1 pr-1">
              <v-text-field
                class="text-body-2"
                v-model="cardNumber1"
                outlined
                dense
                hide-details
                v-mask="'####'"
                type="number"
                autofocus
                ref="cardNumber1"
                @input="card1Event"
              />
            </v-col>
            <v-col cols="3" class="pl-1 pr-1">
              <v-text-field
                class="text-body-2"
                v-model="cardNumber2"
                dense
                hide-details
                outlined
                v-mask="'####'"
                type="number"
                ref="cardNumber2"
                @input="card2Event"
               />
            </v-col>
            <v-col cols="3" class="pl-1 pr-1">
              <v-text-field
                class="text-body-2"
                v-model="cardNumber3"
                dense
                hide-details
                outlined
                v-mask="'####'"
                type="number"
                ref="cardNumber3"
                @input="card3Event"
              />
            </v-col>
            <v-col cols="3" class="pl-1 pr-1">
              <v-text-field
                  class="text-body-2"
                  v-model="cardNumber4"
                  dense
                  hide-details
                  outlined
                  v-mask="'####'"
                  type="number"
                  ref="cardNumber4"
                  @input="card4Event"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-4 mb-2">
            <v-col cols="6">
              <div>카드유효기간</div>
            </v-col>
            <v-col cols="6">
              <div>할부개월</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="3" class="pr-1 pl-1">
              <v-text-field
                  class="text-body-2"
                  v-model="expiration1"
                  outlined
                  dense
                  hide-details
                  v-mask="'##'"
                  type="number"
                  ref="expiration1"
                  @input="expiration1Event"
                  label="월(07)"

              >
              </v-text-field>
            </v-col>
            <v-col cols="3" class="pr-1 pl-1">
              <v-text-field
                  class="text-body-2"
                  v-model="expiration2"
                  outlined
                  dense
                  hide-details
                  v-mask="'##'"
                  type="number"
                  ref="expiration2"
                  label="년(21)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pl-1 pr-1">
              <v-select
                class="text-body-2"
                  :items="installmentItems"
                  outlined
                  item-text="text"
                  item-value="value"
                  v-model="installment"
                  :disabled='installmentFlag'
                  dense
              />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="this.sugiCertification==='Y'">
            <v-col cols="8">
               <div>생년월일(사업자번호)</div>
            </v-col>
            <v-col cols="4">
               <div>비밀번호</div>
            </v-col>
            <v-col cols="8" class="pl-1 pr-1">
              <v-text-field
                  class="text-body-2"
                  v-model="pidNum"
                  outlined
                  type="number"
                  v-mask="'##########'"
                  hint="610101(생년월일) 혹은 8208100121(사업자번호)"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pl-1 pr-1">
              <v-text-field
                  class="text-body-2"
                  v-model="passwd"
                  outlined
                  v-mask="'##'"
                  type="number"
                  hint="비밀번호 앞두자리"
              >
              </v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions class="px-4">
          <v-btn
            block color="success"
            @click="paymentEvent"
            :disabled='!this.paymentValid'
            >
            결제하기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "sugiCardCompont",
  props : ['dialogFlag', 'price' , 'installmentMonths' , 'sugiCertification'],
  computed : {
    paymentValid(){
      // 추가 인증이 필요없는경우
      if(this.sugiCertification==='N' ){
        return ((this.cardNumber1.length + this.cardNumber2.length + this.cardNumber3.length + this.cardNumber4.length) >= 12
            && (this.cardNumber1.length + this.cardNumber2.length + this.cardNumber3.length + this.cardNumber4.length) <= 16
          && this.expiration1.length+ this.expiration2.length) == 4;
      }
      // 추가인증을 해야 하는경우
      else {
        return ((this.cardNumber1.length + this.cardNumber2.length + this.cardNumber3.length + this.cardNumber4.length) >= 12
            && (this.cardNumber1.length + this.cardNumber2.length + this.cardNumber3.length + this.cardNumber4.length) <= 16
            && this.expiration1.length+ this.expiration2.length == 4
            && this.passwd.length === 2
            /**
             * 2024/06/11 김정민
             * 주민등록번호 8자리 입력 시 결제 에러 발생으로 인한 수정
             * **/
            && this.pidNum.length === 6
            || this.pidNum.length === 10);
      }
    },

    // 할부 선택 가능유무
    installmentFlag(){
      if(!this.installmentMonths) return true;
      if(this.installmentMonths <2) return true;
      return this.price < 50000;

    },
    // 할부선택박스처리
    installmentItems(){
      let arr = [];
      for (let index = 0; index <= this.installmentMonths; index++) {
        let obj = {text : '' , value : ''};
        if(index===0) {
          obj.text = "일시불";
          obj.value = "00";
        }
        else if(index===1) continue;
        else if(index < 10){
          obj.text = index+" 개월";
          obj.value = "0"+index;
        }else{
          obj.text = index +" 개월";
          obj.value = index;
        }
        arr.push(obj);
      }
      return arr
    },
  },

  methods: {
    // 포커스 이동 관련 공통화 진행예정
    card1Event(){
      if(this.cardNumber1.length>3){
        this.$refs.cardNumber2.focus();
      }
    },
    card2Event(){
      if(this.cardNumber2.length>3){
        this.$refs.cardNumber3.focus();
      }
    },
    card3Event(){
      if(this.cardNumber3.length>3){
        this.$refs.cardNumber4.focus();
      }
    },
    card4Event(){
      if(this.cardNumber4.length>3){
        this.$refs.expiration1.focus();
      }
    },
    expiration1Event(){
      if(this.expiration1.length>1){
        this.$refs.expiration2.focus();
      }
    },
    closeEvent(){
      this.$emit('sugiCardEvent');
    },
    // 결제버튼
    paymentEvent () {
      // 카드번호
      this.payData.cardNumber = ""+this.cardNumber1 + this.cardNumber2 +this.cardNumber3+this.cardNumber4;
      // 카드유효기간
      this.payData.expiration = ""+this.expiration2+ this.expiration1;
      // 할부개월
      this.payData.installment = this.installment;
      // 카드 생년월일 / 사업자번호
      this.payData.pidNum = this.pidNum;
      // 패스워드 앞자리 2개
      this.payData.passwd = this.passwd;
      this.$emit('sugiCardEvent' , this.payData);
    },
  },
  data() {
    return {
      // 카드번호
      cardNumber1 : "",
      cardNumber2 : "",
      cardNumber3 : "",
      cardNumber4 : "",
      expiration1 : "",
      expiration2 : "",
      pidNum : "",
      passwd : "",
      installment : "00",

      focus : true,
      payData : {
        installment : '00',
        cardNumber:"",
        expiration : "",
        pidNum : '',
        passwd : '',
      },

    }
  },
}
</script>

<style scoped>

</style>
