  <template>
    <v-container
      fill-height
    >
      
    <v-card
      flat
      class="mx-auto"

      
    >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title
          class="text-h3 mt-6"
         
        >OopS!</v-card-title>
        <v-card-subtitle>주소를 확인해주세요!</v-card-subtitle>
        <v-card-actions>
          <v-btn
            class="ml-2"
            
            icon
            height="40px"
            right
            width="40px"
            @click="gopage"
          >
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <v-avatar
        class="ma-1"
        size="200"
        tile
       >
      <v-img :src="imgsrc"></v-img>
      </v-avatar>
    </div>
    </v-card>
      
    </v-container>
  </template>

  <script>

    export default {
      name: 'Page404',
      data() {
        return {
          imgsrc : '/key.png'
        };
      },
      methods : {
        gopage(){
          location.href='http://www.uni-core.co.kr/'
        }
      }
    };
  </script>
  <style scoped>

  </style>


