<template>
  <v-card outlined class="mx-auto" color="grey lighten-3" max-width="500">
    <v-toolbar dark dense flat color="light-green darken-4" class="font-weight-black">
      <v-icon color="indigo lighten-5" class="mr-3">mdi-truck-fast-outline</v-icon>
      <v-toolbar-title>
        배송지 정보
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mx-auto">
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>받는사람</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ info.recipient }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>연락처</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ info.mobilePhone | handPhoneNum }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>주소</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-black">
                   <div :style="{'color' : 'black' , 'font-weight' : '900'}" v-html="this.addrInfo"></div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>배송요청사항</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  <div :style="{'color' : 'black' , 'font-weight' : '900'}" v-html="this.messageInfo"></div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "desination",
  props : {
    info : Object,
  },
  computed : {
    addrInfo(){
      if(!this.info.address) return '';
      return this.info.address.replace(/\n/g, "<br>");
    },
    messageInfo(){
      if(!this.info.message) return '';
      return this.info.message.replace(/\n/g, "<br>");
    }
  },
}
</script>


