<template>
  <v-app>
    <router-view></router-view>
    <AlertDialog/>
    
  </v-app>
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import Footer from "@/components/layout/Footer";
  export default {
    components : {
      AlertDialog,
      Footer
    },
    props: {
      source: String,
    },
    computed: {
    },
    created () {
      this.$vuetify.theme.dark = false;
    },
  }
</script>

<style >
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C5CAE9; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #eff7c7; 
}

td {
    cursor : pointer
  }

#app {
  font-family: 'Nanum Gothic', 'Roboto' ,'sans-serif' , 'Nanum Pen Script', Helvetica, Arial;   /* this was it */
}

 .v-data-table__checkbox .v-icon {
    color: darkgray ;
  }

  .theme--light.v-input input, .theme--light.v-input textarea {
     color: rgba(83, 8, 105, 0.87) !important; 
  }

.v-data-table__mobile-row__header {
    font-size: medium;
}

.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: right;
    font-size: medium;
}

.theme--dark .v-data-table__mobile-table-row td:last-child {
    border-bottom: thick double rgba(0,0,0,.12) !important;
}
</style>