<template>
  <v-dialog
      attach
      v-model="consentflag"
      width="500"
      height="300"
      persistent hide-overlay>
    <v-card color="lime lighten-5" >
      <v-card-title class="font-weight-black">
        이용약관
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <div class="subtitle-2 font-weight-black">제 1조 (목적)</div>
        <div class="subtitle-2 font-weight-black">이 약관은 (주)유니코아(이하 “회사”)가 제공하는 비즈페이 서비스(이하 “서비스”)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 2조 (정의)</div>
        <div class="subtitle-2 mb-5 font-weight-black">이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</div>
        <div class="subtitle-2">1. “비즈페이회원”(이하 “회원”)이란 비즈페이 서비스 이용약관에 따라 회사와 이용계약을 체결하고 회사에서 운영하는 비즈페이 “서비스”를 이용할 수 있는 고객입니다.</div>
        <div class="subtitle-2">2. “비즈페이”란 온라인 및 오프라인 통합 결제 솔루션으로 현장대면결제 시 사용할 수 있는 오프라인 결제 솔루션과 온라인상에서 신용카드, 휴대폰, 가상계좌이체 등으로 결제 시 사용할 수 있는 온라인 결제 솔루션 일체를 말합니다.</div>
        <div class="subtitle-2">3. ”구매자”라 함은 “회원”으로서 서비스를 이용하여 상품을 구매하고자 하는 자를 말합니다.</div>
        <div class="subtitle-2">4. ”판매자”라 함은 “회원”으로서 서비스를 이용하여 상품 및 서비스 판매하고, 해당 판매 대금을 받고자 하는 자를 말합니다.</div>
        <div class="subtitle-2">5. "아이디"라 함은 "회원"의 “서비스” 로그인과 이용을 위하여 사용되는 휴대전화번호 또는 회원이 지정한 영문숫자조합을 의미합니다.</div>
        <div class="subtitle-2">6. ”결제정보”라 함은 상품대금결제를 위해 “회원”이 입력한 카드정보 및 휴대폰결제 정보를 말합니다.</div>
        <div class="subtitle-2">7. “전자지급결제대행서비스”라 함은 전자적 방법으로 상품의 매매에 있어서 지급결제정보를 송신하거나 수신하는 것, 또는 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 3조 (약관의 게시와 개정)</div>
        <div class="subtitle-2">1. 회사는 이 약관의 내용을 회원가입 및 결제화면에 게시합니다.</div>
        <div class="subtitle-2">2. 본 약관은 “서비스”에 가입된 “회원”을 포함하여 약관에 동의 후 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력을 발생합니다.</div>
        <div class="subtitle-2">3. 본 약관은 수시로 변경될 수 있으며, 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 초기화면에 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지하며, 일정기간 서비스 내 로그인 시 동의창 또는 전자적 수단을 통해 따로 명확히 통지하도록 합니다.</div>
        <div class="subtitle-2">4. 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</div>
        <div class="subtitle-2">5. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 4조 (약관의 해석)</div>
        <div class="subtitle-2">1. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정보통신망이용촉진등에관한법률(이하 “정통망법”), 전자상거래등에서의소비자보호에관한법률 등 관련법령 또는 상관례에 따릅니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 5조 (서비스 이용계약 체결 및 승낙, 거부, 유보)</div>
        <div class="subtitle-2">1. 이용계약은 "회원"이 서비스이용에 대하여 동의를 하는 것으로써 체결됩니다.</div>
        <div class="subtitle-2">2. 회사는 다음 각 호에 해당하는 구매자의 서비스 이용에 대해서는 결제승인을 하지 않거나 사후에 결제승인을 취소할 수 있습니다.</div>
        <div class="subtitle-2">① 만 14세 미만의 자가 이용신청을 하는 경우</div>
        <div class="subtitle-2">② 신청자가 이 약관에 의하여 이전에 서비스 이용자격을 상실한 적이 있는 경우, 다만, 회원자격 상실 후 1년이 경과한 자로서 회사의 승인을 얻은 경우에는 예외로 함.</div>
        <div class="subtitle-2">③ 타인의 휴대폰을 도용하여 서비스를 이용한 경우</div>
        <div class="subtitle-2">④ 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</div>
        <div class="subtitle-2">⑤ 서비스 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</div>
        <div class="subtitle-2">⑥ 서비스를 이용함에 있어 부적절한 행위를 할 우려가 있다고 인정되거나 서비스를 정상적으로 이용할 수 없다고 합리적으로 판단되는 경우</div>
        <div class="subtitle-2">3. 제1항에 따른 신청에 있어 회사는 "회원"의 종류별로 판매자 또는 구매자에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다. 실명확인의 경우 법령에 따라 이용 가능한 신용정보업자 또는 신용정보집중기관을 통하여 주민등록번호와 성명의 일치 여부를 확인함으로써 실명확인절차를 진행하며, 회사는 실명확인절차를 취할 수 없는 이용신청에 대해서 이용신청자에게 증빙자료를 요청할 수 있습니다.</div>
        <div class="subtitle-2">4. 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 서비스를 중지할 수 있습니다.</div>
        <div class="subtitle-2">5. 제2항과 제4항에 따라 서비스이용을 제한하거나 유보한 경우, 회사는 원칙적으로 이를 서비스이용자의 화면에 게시하거나 전자적 수단을 등을 통해 알리도록 합니다.</div>
        <div class="subtitle-2">6. 판매자회원 중 법인은 사업자회원으로 가입신청하여야 하고, 개인사업자의 경우에도 그 사업과 관련하여 서비스를 이용하고자 하는 때에는 사업자회원으로 가입하여야 합니다. 사업자회원으로 가입하고자 하는 자는 회사의 요청에 따라 사업자등록증, 대표자 주민등록증사본, 인감증명서, 통신판매업신고증사본, 결제 또는 판매대금 정산을 위한 대표자 또는 법인 명의의 계좌정보 등의 증빙자료와 추가 정보를 제공하여야 하며, 상기 자료는 “전자지급결제대행서비스업자”에 제공될 수 있습니다.</div>
        <div class="subtitle-2">7. 회사는 판매자회원의 건별 결제금액이 10만원을 초과하거나 또는 월매출 300만원 초과 시 해당 판매자회원에게 별도의 서면계약 및 보증보험 가입을 요청할 수 있습니다. 해당 회원이 이 서면계약의 날인 및 보증보험 가입을 진행하지 않을 경우 회사는 해당 판매자회원에 대한 정산대금 지급을 보류할 수 있습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 6조 (아이디 및 비밀번호)</div>
        <div class="subtitle-2">1. 회사는 회원이 원활하게 이용 할 수 있도록 회원의 아이디와 비밀번호를 제공하고 있습니다.</div>
        <div class="subtitle-2">2. 아이디는 실명 1인당 1개의 아이디를 사용하는 것을 원칙으로 합니다.</div>
        <div class="subtitle-2">3. 회원의 아이디 및 비밀번호에 관한 관리책임은 회원 본인에게 있으며 제3자에게 자신의 아이디 및 비밀번호를 알려주거나 이용하게 해서는 안됩니다.</div>
        <div class="subtitle-2">4. 회원이 자신의 아이디 및 비밀번호를 도용 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하여 그 안내에 따라야 합니다.</div>
        <div class="subtitle-2">5. 회사는 회사의 귀책사유 없이 회원이 자신의 아이디 및 비밀번호를 도용 당한 데 따른 손해에 대해서는 법적 책임을 부담하지 않습니다.</div>
        <div class="subtitle-2">6. 아이디개설에 따른 가입비는 어떠한 경우에도 환불되지 않습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 7조 (등록비/관리비의 청구 및 반환)</div>
        <div class="subtitle-2">1. 다음 각 호에 해당하는 경우 회사는 “회원”에게 등록비나 관리비를 청구할 수 있습니다.</div>
        <div class="subtitle-2">① “회원”이 서비스 이용을 위해 본 계약을 체결하는 경우</div>
        <div class="subtitle-2">② “회원”이 중지된 서비스에 대한 재사용을 요청하는 경우</div>
        <div class="subtitle-2">③ 회사에게 추가 계정 또는 추가 서비스를 요청할 경우</div>
        <div class="subtitle-2">④ 기타 본 계약의 체결과 연장에 있어 “회원”에게 별도의 비용이 발생되는 경우</div>
        <div class="subtitle-2">2. “회원”이 납부한 등록비는 회사의 귀책이 없는 한 서비스 이용 여부에 관계없이 환불하지 않는다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 8조 (계약해제, 해지 등)</div>
        <div class="subtitle-2">1. "회원"은 언제든지 “고객센터”를 통하여 이용계약 해지 신청을 할 수 있으며, 회사는 관련법률 및 개인정보취급방침에서 정하는 바에 따라 이를 지체 없이 처리하여야 합니다.</div>
        <div class="subtitle-2">2. 회원은 해지의사를 통지하기 전에 모든 구매 또는 판매중인 거래절차를 완료, 철회 또는 취소해야만 합니다. 이 경우 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 9조 (비즈페이 서비스 내용)</div>
        <div class="subtitle-2">1. “비즈페이“는 회사에서 제공하는 온/오프라인 결제 솔루션입니다.</div>
        <div class="subtitle-2">2. “비즈페이”는 “구매자”에게 직접 상품을 판매하지 않습니다.</div>
        <div class="subtitle-2">3. “비즈페이“가 제공하는 서비스는 “판매자”와 “구매자”간 상품의 매매가 이루어질 수 있도록 "비즈페이서비스”를 통해 “판매자“ “구매자“ 간 “전자지급결제대행”서비스를 제공하는 것에 그치며, 어떠한 경우에도 “판매자” 및 “구매자”를 대리하지 않습니다.</div>
        <div class="subtitle-2">4. 회사는 “회원”간 상품의 매매가 이루어 질 수 있도록 거래 중계서비스를 제공하는 것이므로 “회원” 상호간에 이루어지는 상품의 매매와 관련된 일체의 위험과 책임은 해당 “회원”이 부담하고 회사는 어떠한 책임도 부담하지 않습니다.</div>
        <div class="subtitle-2">5. 회원 간에 이루어진 상품의 매매와 관련된 책임은 거래 당사자인 회원 스스로에게 있습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 10조 (판매자 및 구매자의 의무)</div>
        <div class="subtitle-2">1. 판매자의 의무</div>
        <div class="subtitle-2">① 상품의 등록은 반드시 판매목적으로, 판매가 가능한 상품만을 등록하여야 하며, 상품의 등록은 회사가 정한 양식에 따라 진행하여야 합니다.</div>
        <div class="subtitle-2">② 판매자는 상품의 매매 및 결제와 관련하여 관련법령을 준수하여야 하며, 이 약관 및 회사가 서비스 화면에서 고지하는 내용을 준수하여야 합니다. 관련 법령, 이 약관 및 고지내용을 위반하거나 이행하지 아니하여 발생하는 모든 손실, 손해에 대하여 판매자가 책임을 부담합니다.</div>
        <div class="subtitle-2">③ 회사는 판매자가 진행한 결제에 대하여 어떠한 보증이나 대리를 하지 않습니다. 따라서 판매자가 진행한 결제와 관련하여 판매자가 구매자에게 회사가 보증이나 대리를 한 것으로 오인하게 하는 내용을 게재하여서는 안 됩니다.</div>
        <div class="subtitle-2">④ 판매자는 구매자의 거래취소 요청이 있는 경우 이를 타당한 이유없이 거부하여서는 안되며, 부당한 요금을 요구하여서는 안됩니다.</div>
        <div class="subtitle-2">⑤ 판매자는 “전자상거래등에서의 소비자보호에 관한 법률” 등 상품의 매매와 관련한 법령에서 정하는 기간동안 필요자료를 보관하여야 합니다.</div>
        <div class="subtitle-2">⑥ 판매자는 적법한 구매자를 대상으로만 구매요청을 하여야 하며, 불특정다수를 대상으로 구매요청을 함으로써 구매자와 회사에 손실, 손해가 발생할 경우 구매자와 회사에 해당 손실, 손해를 배상하여야 합니다.</div>
        <div class="subtitle-2">⑦ 판매자는 구매자와 상품매매 절차에서 분쟁이 발생한 경우 분쟁의 해결을 위하여 성실히 임하여야 하며, 분쟁해결의 불성실로 인하여 구매자와 회사에 발생한 모든 손실, 손해를 부담하여야 합니다.</div>
        <div class="subtitle-2">2. 구매자의 의무</div>
        <div class="subtitle-2">① 구매자는 상품을 구매하기 전에 반드시 판매자가 게재한 상품의 내용과 거래의 조건을 확인한 후 구매를 하여야 합니다. 매매부적합 상품임을 알거나 알 수 있음에도 이를 구매하거나 구매하고자 한 경우 또는 구매하려는 상품의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 모든 손실, 손해에 대한 책임은 구매자가 부담하여야 합니다.</div>
        <div class="subtitle-2">② 구매자는 구매의사 없이 판매자의 상품에 구매표시 및 결제동의를 하여서는 안 됩니다. 구매의사 없이 고의적으로 판매자의 상품판매의 기회를 방해한 것이 적발되는 경우 회사는 해당 사용자의 서비스 이용을 정지하는 등의 조치를 취할 수 있습니다.</div>
        <div class="subtitle-2">③ 구매자는 이 약관 및 회사가 서비스 화면에서 고지하는 내용을 준수하여야 하며, 약관 및 고지내용을 위반하거나 이행하지 아니하여 발생하는 모든 손실, 손해에 대하여 책임을 부담합니다.</div>
        <div class="subtitle-2">④ 회사는 판매자가 게재한 상품의 내용과 거래조건에 대해서 어떠한 보증이나 대리를 하지 않습니다. 따라서 구매자는 상품 구매시 스스로의 책임하에 상품을 구매하여야 합니다.</div>
        <div class="subtitle-2">⑤ 구매자는 구매한 상품에 거래취소 또는 반품의 원인이 발생한 경우 수령한 상품을 임의로 사용하거나 훼손되도록 방치하여서는 안됩니다. 거래취소 또는 반품 상품의 임의사용이나 상품보관의 미흡으로 인한 상품훼손에 대하여 합당한 비용을 부담하여야 합니다.</div>
        <div class="subtitle-2">⑥ 구매자는 판매자와 상품매매 절차에서 분쟁이 발생한 경우 분쟁의 해결을 위하여 성실히 임하여야 하며, 분쟁해결의 불성실로 인하여 판매자와 회사에 발생한 모든 손실, 손해를 부담하여야 합니다.</div>
        <div class="subtitle-2">⑦ 구매자는 상품의 구매 시 결제방법을 사용함에 있어 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을 임의사용 하여서는 안 됩니다. 타인의 결제수단을 임의로 사용함으로써 발생하는 회사, 결제수단 소유자, 판매자의 손실과 손해에 대한 모든 책임은 구매자가 부담합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 11조 (판매상품 등록 및 구매자 지정)</div>
        <div class="subtitle-2">1. 판매자는 판매상품의 등록시 구매자가 구매상품에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게 기재하여야 합니다.</div>
        <div class="subtitle-2">① 상품명</div>
        <div class="subtitle-2">상품명은 구매자가 상품을 정확하게 인지할 수 있도록 구체적으로 작성하여야 합니다.</div>
        <div class="subtitle-2">② 결제금액</div>
        <div class="subtitle-2">판매자는 판매하고자 하는 상품의 결제금액을 기재하여야 하며, 최소 1,000원 이상이어야 합니다.</div>
        <div class="subtitle-2">③ 상품설명</div>
        <div class="subtitle-2">상품에 대한 정보는 사실대로 명확하게 기재하여야 합니다. 또한 실수 또는 착오없이 거래하는 데 필요한 거래조건을 판매자 메모 또는 상품설명에 구체적으로 명시하여야 합니다. 허위 또는 과장 정보를 기재한 경우, 모순되는 내용의 정보가 기재되어 있는 경우, 이 약관에 반하는 거래조건이 기재된 경우 회사는 해당거래를 취소할 수 있으며, 거래의 취소 또는 중지로 인한 모든 위험과 책임은 판매상품을 등록한 해당 “회원”이 부담해야 합니다. 거래가 취소되거나 중지되면, 회사는 서비스화면 또는 SMS(단문메세지) 등의 방법을 통하여 해당 판매자와 구매자에게 그 사실을 통지합니다.</div>
        <div class="subtitle-2">④ 고객결제가능 수단</div>
        <div class="subtitle-2">판매자는 회사가 제공한 결제방법 중 구매자가 결제 시 사용할 수 있는 결제방법을 설정하여야 합니다.</div>
        <div class="subtitle-2">⑤ 구매자지정</div>
        <div class="subtitle-2">온라인 원격결제나 건당 10만원 이상의 대면결제 건에 대해 판매자는 구매자의 정확한 휴대전화번호를 입력하여 결제를 요청/진행해야 합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 12조 (매매 부적합 상품)</div>
        <div class="subtitle-2">1. 회사는 등록된 상품이 온라인상에서의 매매, 교환, 유통에 적합하지 않은 상품인 경우에는 직권 또는 권리주장자의 요청에 따라 해당 상품 등록을 삭제, 취소하거나 중지할 수 있습니다.</div>
        <div class="subtitle-2">2. 회사는 등록된 상품이 법령에 위반되거나, 미풍양속을 저해하거나 기타 위법 또는 탈법행위와 관련되거나 그러한 목적이 있는 경우 또는 회사의 정책상 필요에 의한 경우에는 직권으로 이를 삭제, 취소하거나 중지할 수 있습니다.</div>
        <div class="subtitle-2">* 매매불가상품 : 관련법령 또는 사회통념상 판매 또는 유통이 불가한 제품을 말합니다.</div>
        <div class="subtitle-2">3. 회사는 권리의 보호를 위하여, 권리주장자가 회사가 정한 절차 및 방식에 따라 신고 또는 요청을 하는 경우, 당해 상품등록을 삭제, 취소 또는 일시 중지하고 이를 해당 상품의 판매자에게 통지합니다. 이러한 경우 회사는 당해 권리주장자의 신원, 신고 또는 요청이 회사의 절차 및 방식에 부합하는지 여부만을 서면으로 심사할 책임을 지는 것에 그치며, 당해 권리의 실질적 유효성 및 범위, 당해 권리의 주체 및 동 권리에 관련된 계약 또는 실질적 관계 등에 대한 심사를 행하지 않습니다. 회사는 권리주장자의 신고 또는 요청에 의한 상품등록의 삭제, 취소 또는 일시 중지에 대해 일체의 책임을 지지 않으며, 이는 권리주장자와 판매자 사이에서 해결되어야 합니다.</div>
        <div class="subtitle-2">4. 회사가 직권 또는 권리주장자의 신고 또는 요청에 따라 해당 상품등록을 삭제, 취소하거나 중지한 경우, 판매자는 이에 대한 이의를 회사가 정한 절차와 방식에 따라 소명함으로써 회사의 조치에 대한 중단을 요청할 수 있습니다. 이 경우 회사는 판매의 재개, 재등록 등의 조치를 취하고 이를 권리주장자에게 통보합니다. 판매자의 소명이 있는 경우, 회사는 해당 소명이 회사가 정한 절차와 방식에 부합하는 지 여부만을 서면으로 심사하는 것에 그치며 해당 소명의 유효성, 적법성, 타당성 및 판매자의 권리 등에 대한 심사를 행하지 않습니다. 회사는 판매자의 소명에 따른 판매의 재개, 재등록 등에 대하여 일체의 책임을 지지 않으며, 이는 권리주장자와 판매자 사이에서 해결되어야 합니다.</div>
        <div class="subtitle-2">5. 매매불가상품의 등록을 이유로 거래가 삭제, 취소되거나 중지된 경우, 회사는 회사가 제공하는 서비스를 이용하실 수 없도록 판매자의 회원 자격을 제한할 수 있습니다.</div>
        <div class="subtitle-2">6. 회사는 매매불가상품 여부를 확인하기 위하여(상품 또는 상품등록정보 등에 대하여 이의, 신고가 접수된 경우를 포함) 해당 거래의 진행을 일시 중지할 수 있습니다. 이 경우, 회사는 판매자와 구매자에게 중지사실을 통지합니다. 회사는 이후 매매부적합상품이 아닌 것으로 확인된 경우 즉시 해당 거래가 정상적으로 진행될 수 있도록 조치합니다.</div>
        <div class="subtitle-2">7. 매매불가상품임을 알거나 알 수 있는 상태에서 상품을 구매한 경우에는 매매대금의 입출금을 중개하지 않을 수 있습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 13조 (구매 및 대금결제)</div>
        <div class="subtitle-2">1. 회사는 온라인 및 오프라인 거래가 가능한 결제수단을 제공하며, 판매자는 회사가 정하는 범위내에서 결제수단을 선택할 수 있습니다.</div>
        <div class="subtitle-2">2. 온라인결제 솔루션 이용 시 판매자는 구매자가 구매하고자 하는 정확한 상품명과 결제금액의 입력 후 구매자 휴대전화번호의 입력을 통해 구매를 요청하여야 합니다.</div>
        <div class="subtitle-2">3. 구매자는 판매자가 입력한 상품의 정보를 충분히 확인한 후 구매를 진행해야 합니다.</div>
        <div class="subtitle-2">4. 구매자가 상품의 대금결제를 완료한 것은 판매자가 제공한 상품 정보와 판매 조건 등에 동의한 것이며, 회사는 상품의 품질과 성능을 보장하지 않습니다.</div>
        <div class="subtitle-2">5. 구매자는 판매자가 허가하는 결제수단 내에서 결제를 진행할 수 있습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 14조 (취소/환불 및 반품)</div>
        <div class="subtitle-2">1. 구매자는 전화 등의 수단을 통해 판매자에게 취소를 요청할 수 있으며, 이 경우 판매자가 판매취소를 해야 취소처리가 됩니다.</div>
        <div class="subtitle-2">2. 판매자는 구매자 요청, 상품 재고 부족 등의 사유로 판매취소를 요청할 수 있으며, 회사는 즉시 취소처리합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 15조 (판매대금의 정산)</div>
        <div class="subtitle-2">1. 상품판매대금 정산의 기본조건은 아래와 같습니다.</div>
        <div class="subtitle-2">① 판매대금의 정산은 "서비스"의 ㈜유니코아 를 통해 진행됩니다</div>
        <div class="subtitle-2">② 판매자는 판매대금을 정산 받기 위해 사업자등록증, 통장사본, 대표자신분증사본을 회사 및 정산대행사인 ㈜유니코아에게 제출하여야 합니다.</div>
        <div class="subtitle-2">③ 판매대금 정산은 판매자가 지정한 정산 계좌로 입금합니다.</div>
        <div class="subtitle-2">④ 총 판매대금 중 결제금액에서 결제수수료를 제외한 금액이 입금됩니다.</div>
        <div class="subtitle-2">⑤ 할인 및 쿠폰/포인트/스탬프 등으로 주문금액과 결제금액이 상이한 경우 결제금액기준으로 정산이 진행됩니다.</div>
        <div class="subtitle-2">⑥ 결제수수료는 결제수단과 상점의 영세/중소사업자 여부에 따라 각각 별도의 수수료율이 적용됩니다.</div>
        <div class="subtitle-2">⑦ 판매대금 정산 기준일은 기본 D+3일(영업일기준)이며, 판매자용 관리자페이지 내의 서비스 신청을 통해 D+2일 정산, D+1일 정산의 이용도 가능합니다.</div>
        <div class="subtitle-2">⑧ "서비스"의 ㈜유니코아는 판매자가 등록한 정산입금계좌 정보와 판매자명이 일치할 경우에만 정산지급합니다. 다만, 판매자용 관리자페이지 내의 대리정산 서비스를 이용할 경우 대리정산이 승인된 계좌로 정산지급됩니다.</div>
        <div class="subtitle-2">⑨ 송금계좌의 실명이 확인되지 않는 경우 회사는 실명확인이 되거나 판매자의 실명증빙이 접수되는 때까지 대금의 송금을 보류할 수 있습니다</div>
        <div class="subtitle-2">⑩ 회사는 정산과 관련된 모든 업무를 “전자지급결제대행서비스업자”를 통해 진행할 수 있으며, 판매자 구매자간 모든 거래정보를 “전자지급결제대행서비스업자”에 제공할 수 있습니다.</div>
        <div class="subtitle-2">2. 회사는 아래의 사유가 발생하는 경우 상품판매대금의 출금을 보류할 수 있습니다.</div>
        <div class="subtitle-2">① 구매자가 신용카드로 결제한 경우, 민원처리나 유관기관의 요청 등으로 조사가 필요할 경우 회사는 결제대행 서비스가 “여신전문금융거래법”상의 규정에 따라 정당하게 이루어졌는지 여부를 확인하기 위해 필요한 기간 동안 정산지급을 보류할 수 있습니다.</div>
        <div class="subtitle-2">② 법원 등 제3자가 자격을 갖추어 상품판매대금의 출금보류를 요청한 경우 회사는 관련 상품판매대금의 출금을 보류요청이 해제될 때까지 보류할 수 있습니다.</div>
        <div class="subtitle-2">3. 회사가 상품판매대금의 지급을 보류한 경우 회사는 해당 판매자 또는 관련자에게 그러한 사실을 통보하며, 판매자는 서비스의 이용과 관련하여 정당한 이용임을 확인하는 증빙자료와 소명자료를 회사에 제공하여야 합니다.</div>
        <div class="subtitle-2">4. 회사는 판매자로부터 증빙자료와 소명자료가 접수되면, 이를 확인한 후 정당한 이용으로 판단되는 경우 해당 상품판매대금의 지급보류를 해제하여 관련 대금을 지급합니다.</div>
        <div class="subtitle-2">5. 회사는 판매자 또는 관련자가 제출한 증빙자료와 소명자료의 내용이 정당한 이용으로 판단하기 어렵다고 판단이 되는 경우 또는 증빙자료와 소명자료가 기한 내에 접수되지 않는 경우 관련 대금의 지급보류를 해제하지 않을 수 있습니다.</div>
        <div class="subtitle-2">6. 상품판매대금을 수령할 계좌의 변경은 회원 정보 변경 화면에서 할 수 있으며, 판매대금 정산이 진행중일 경우에는 계좌변경이 불가능 합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 16 조 (솔루션이용수수료)</div>
        <div class="subtitle-2">1. 솔루션을 제공함에 있어 회사는 솔루션 이용에 따르는 솔루션이용수수료를 부과하며, 기본적으로 결제수수료가 이에 해당됩니다.</div>
        <div class="subtitle-2">2. 회사의 솔루션이용수수료는 서비스를 이용하여 구매 승인된 거래에 대하여 판매자에게 부과하는 수수료입니다.</div>
        <div class="subtitle-2">3. 솔루션이용수수료는 정산 시 상품판매대금에서 공제합니다.</div>
        <div class="subtitle-2">4. 솔루션이용수수료율은 결제수단에 따라 각각 다른 수수료율이 적용됩니다.</div>
        <div class="subtitle-2">5. 회사는 솔루션이용수수료율을 서비스 화면에 공지합니다.</div>
        <div class="subtitle-2">6. 솔루션이용수수료율은 회사의 정책에 따라 변동될 수 있으며, 변동 시 서비스 웹페이지 공지사항에 이를 공지합니다.</div>
        <div class="subtitle-2">7. 솔루션이용수수료율 적용 후 원단위 미만 금액은 절사합니다.</div>
        <div class="subtitle-2">8. 회사는 서비스의 종류와 제공내역, 이용방법이 변경되는 경우 서비스의 수수료를 변경할 수 있으며, 변경된 수수료는 변경된 수수료가 적용되는 날부터 결제되는 거래건에 대하여 적용합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 17 조 (회사의 의무)</div>
        <div class="subtitle-2">1. 회사는 서비스 이용정책 또는 이용약관을 준수합니다.</div>
        <div class="subtitle-2">2. 회사는 관련법률과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.</div>
        <div class="subtitle-2">3. 회사는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.</div>
        <div class="subtitle-2">4. 회사는 서비스를 이용하는 “회원”간 분쟁이 발생하여 그 분쟁의 해결이 원활하지 않은 경우 분쟁의 원활한 해결을 위하여 노력합니다.</div>
        <div class="subtitle-2">5. 회사는 서비스 제공자로서 관련 법령에서 정하는 기간동안 필요정보를 보관합니다. 또한 보관중인 자료에 대하여 권리가 있는 자의 요청이 있는 경우 관련 자료를 제공할 수 있습니다.</div>
        <div class="subtitle-2">6. 회사는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자적 표시방법 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 18 조 ("회원"의 의무)</div>
        <div class="subtitle-2">1. "회원"은 다음 행위를 하여서는 안 됩니다.</div>
        <div class="subtitle-2">① 신청 또는 변경 시 허위내용의 등록</div>
        <div class="subtitle-2">② 타인의 정보도용</div>
        <div class="subtitle-2">③ 회사가 게시한 정보의 변경</div>
        <div class="subtitle-2">④ 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</div>
        <div class="subtitle-2">⑤ 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</div>
        <div class="subtitle-2">⑥ 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</div>
        <div class="subtitle-2">⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위</div>
        <div class="subtitle-2">⑧ 기타 불법적이거나 부당한 행위</div>
        <div class="subtitle-2">2. "회원" 및 "비회원"은 관련법률, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 19 조 ("서비스" 이용시간)</div>
        <div class="subtitle-2">1. "서비스"는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</div>
        <div class="subtitle-2">2. 회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 20 조 (“서비스”의 중단 및 변경)</div>
        <div class="subtitle-2">1. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다.</div>
        <div class="subtitle-2">2. 회사는 운영상, 기술상 상당한 이유가 있는 경우 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.</div>
        <div class="subtitle-2">3. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</div>
        <div class="subtitle-2">4. 본조 1,2항에 따라 “서비스”가 변경, 중단 될 경우 는 제21조["회원"에 대한 통지]에 정한 방법으로 "회원"에게 통지합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 21 조 ("회원"에 대한 통지)</div>
        <div class="subtitle-2">1. 회사가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 문자메시지(SMS, LMS 등), 모바일 단말기 어플리케이션 알림메세지 등으로 할 수 있습니다.</div>
        <div class="subtitle-2">2. 회사는 "회원" 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 22 조 (이용제한 등)</div>
        <div class="subtitle-2">1. 회사는 다음과 같은 사유가 있는 경우, "서비스" 이용을 제한할 수 있습니다.</div>
        <div class="subtitle-2">① ”회원”이 회사가 제공하는 서비스를 그 목적에 벗어나거나 위배하여 이용한 것으로 확인된 경우</div>
        <div class="subtitle-2">② 회원”이 다른 “회원” 또는 기타 타인의 권리나 명예, 신용, 정당한 이익을 방해하거나 침해한 경우</div>
        <div class="subtitle-2">③ 회원”이 회사의 권리나 명예, 정당한 이익을 방해 또는 침해하거나 회사의 원활한 서비스 제공을 방해 하거나 방해하고자 시도한 경우</div>
        <div class="subtitle-2">④ ”회원”이 사회의 안녕질서, 공공질서, 미풍양속에 위배되는 거래행위를 하거나 시도한 경우</div>
        <div class="subtitle-2">⑤ 기타 회원이 법령 및 약관에 위배되는 행위를 한 경우</div>
        <div class="subtitle-2">2. 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다. 회사가 회원 자격을 상실시키는 경우에는 회원등록을 말소합니다.</div>
        <div class="subtitle-2">3. 회사가 회원 자격을 일시정지·상실시키는 경우, 회원에게 이를 통지하고, 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</div>
        <div class="subtitle-2">4. 회사는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정통망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한 혜택이 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.</div>
        <div class="subtitle-2">5. 회사는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.</div>
        <div class="subtitle-2">6. 회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.</div>
        <div class="subtitle-2">7. 본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제21조["회원"에 대한 통지]에 따라 통지합니다.</div>
        <div class="subtitle-2">8. "회원"은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 "서비스"의 이용을 재개합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 23 조 (개인정보보호 의무)</div>
        <div class="subtitle-2">1. 회사는 "정통망법" 등 관련법률이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법률 및 회사의 개인정보취급방침이 적용됩니다.</div>
        <div class="subtitle-2">2. 회사가 “회원”의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 해당 “회원”의 동의를 받습니다.</div>
        <div class="subtitle-2">3. 회사가 회원 자격을 일시정지·상실시키는 경우, 회원에게 이를 통지하고, 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</div>
        <div class="subtitle-2">① 배송업무상 배송업체에게 배송에 필요한 최소한의 “회원”의 정보(성명, 주소, 전화번호)를 알려주는 경우</div>
        <div class="subtitle-2">② 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</div>
        <div class="subtitle-2">③ 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우</div>
        <div class="subtitle-2">④ 도용방지를 위하여 본인확인에 필요한 경우</div>
        <div class="subtitle-2">⑤ 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</div>
        <div class="subtitle-2">⑥ 당사 또는 제휴사와 관련하여 합병, 인수, 포괄적 영업양도 등이 있는 경우 합병 후 당사, 인수당사 및 영업양수인에 대하여 제공하는 경우</div>
        <div class="subtitle-2">4. 회사가 제2항과 제3항에 의해 “회원”의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 “정통망법”에서 규정한 사항을 미리 명시하거나 고지해야 하며 “회원”는 언제든지 이 동의를 철회할 수 있습니다.</div>
        <div class="subtitle-2">5. “회원”은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. “회원”이 오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</div>
        <div class="subtitle-2">6. 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 다만, 아래의 경우에는 회원 정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원 정보를 그 보관의 목적으로만 이용합니다.</div>
        <div class="subtitle-2">① 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.</div>
        <div class="subtitle-2">② ”서비스” 이용계약을 해지하거나 회사로부터 서비스 이용정지조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로 이용계약종료 후 5년간 아이디, 성명 또는 상호, 연락처, 주소를 비롯하여 이용계약 해지와 서비스 이용정지와 관련된 정보 등의 필요정보를 보관합니다.</div>
        <div class="subtitle-2">③ 기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원 정보를 보관합니다.</div>
        <div class="subtitle-2">7. 회사는 새로운 업체가 제휴사 또는 제휴영업점의 지위를 취득할 경우 제21조에서 정한 것과 같은 방법을 통하여 공지합니다. 이 때 “회원”이 별도의 이의제기를 하지 않을 경우 회사 서비스 제공이라는 필수적인 목적을 위해 해당 개인 정보 제공 및 활용에 동의한 것으로 간주합니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black">제 24조 (책임제한)</div>
        <div class="subtitle-2">1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.</div>
        <div class="subtitle-2">2. 회사는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.</div>
        <div class="subtitle-2">3. 회사는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</div>
        <div class="subtitle-2 mt-5 font-weight-black" >제 25조 (준거법 및 재판관할)</div>
        <div class="subtitle-2">1. 회사와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.</div>
        <div class="subtitle-2">2. 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.</div>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="success" @click="confirmEvent">확인</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "consentForm",
  props : ['consentflag'],
  methods: {
    confirmEvent(){
      this.$emit('confirmEvent' , '약관을 확인하셨습니다.');
    }
  },
}
</script>

<style scoped>

</style>