import { request } from "./index";
// sms link 결제용
const smsLinkApi = {
    // sms 결제요청 상품정보 가져오기
    smsGoodsInfo(slid){
        return request('get' , `/ext/smspay/${slid}`);
    },
    // 결제요청
    smsPaymentRequest(payload){
        return request('post' , '/ext/smspay' , payload);
    },
    // 링크결제 상품정보
    linkGoodsInfo(id){
        return request('get' , `/ext/linkpay/${id}`);
    },
    // smslink 결제 생성
    insertSmsGoods(payload){
        return request('post' , '/ext/smsInsert' , payload);
    },
    // smslink 카드 결제 정보조회
    smsCardPaymentInfo(id){
        return request('get' , `/ext/smsCardInfo/${id}`);
    },
    // sms 결제완료이후 정보 조회
    smsPaymentResult(id){
        return request('get' , `/ext/smsPayResultInfo/${id}`);
    }
}

export default smsLinkApi;
