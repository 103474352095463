<template>
  <v-container fill-height>
    <v-card  class="mx-auto" max-width="400">
      <v-app-bar
          color="deep-purple"
          dark
      >
        <v-icon color="#FAEBD7" class="pr-2">mdi-unicode</v-icon>{{title}}
      </v-app-bar>
      <v-card-text>
        <p class="text-subtitle-1 text--primary ">
          구매정보
        </p>
        <v-divider></v-divider>
        <v-row dense class="mt-3">
          <v-col cols="4" >
            <v-subheader>상품명</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field
                filled

                dense
                disabled
                v-model="goods.name"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-subheader>판매금액</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field
                filled

                dense
                suffix="원"
                v-model="goods.price"
                disabled
            ></v-text-field>
          </v-col>
          <v-col cols="4" >
            <v-subheader>상호</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field
                filled
                v-model="goods.mberName"
                dense
                disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <p class="text-subtitle-1 text--primary mt-5">
          결제정보
        </p>
        <v-divider></v-divider>
        <v-row dense class="mx-auto" v-if="this.cardDisplay">

          <CreditCard :paydata="this.payData" :sugiCertification="this.sugiCertification" @loadingEvent="this.loadingEvent"
                      @payClear="this.payClear" ></CreditCard>
        </v-row>

      </v-card-text>
    </v-card>
    <v-dialog
        persistent
        v-model="this.alertDialog"
        max-width="400"
    >
      <v-alert
          dark
          style="opacity: 0.7"
          colored-border
          prominent
          :color="this.alertColor"
          icon="mdi-alert-circle-outline"
          border="left"
          elevation=100
          class="ma-0"
      >
        <v-row align="center">
          <v-col>
            {{this.alertMessage}}
          </v-col>
          <v-col class="shrink">
            <v-btn
                icon
                large
                @click="clickEvent"
            >
              <v-icon>mdi-check-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>


    <v-overlay
        v-model="this.overlay">
      <v-progress-circular
          :size="130"
          :width="15"
          color="teal"
          indeterminate
          :rotate="360"
      > {{overlayMassage}}</v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import CreditCard from "../components/sugiPay/CreditCard";
import {currency} from "@/util/filter.js";
import { mapActions , mapMutations} from 'vuex';
import axios from 'axios';
export default {
  name: "QrPayment",
  mounted() {
    document.title = '비즈페이 수기결제시스템';
    this.overlay = true;
    if(this.orderNoChk()){
      this.fetchData()
    }
  },
  components: {
    CreditCard
  },

  computed : {
    goods(){
      try {
        if(!!this.$store.state.qrStore.goods) {
          return {
            name : this.$store.state.qrStore.goods.orderName,
            price :  currency(this.$store.state.qrStore.goods.orderPrice),
            mberName: this.$store.state.qrStore.goods.mberName
          }
        }else {
          return {
            name : '상품정보가 없습니다',
            price : 0,
            mberName : '상호'
          }
        }
      } catch (error) {
        console.error(error);
        return {};
      }
    }
  },

  data() {
    return {
      overlay : true,
      overlayMassage : '조회중...',
      alertDialog : false,
      alertColor : 'red',
      alertMessage : '경고',
      title : '신용카드 수기결제',
      cardDisplay : true,
      sugiCertification : true,
      payData : {
        price : this.$store.state.qrStore.goods.price,
        orderName : this.$store.state.qrStore.goods.orderName,
        installment : this.$store.state.qrStore.goods.installment,
        mberId: this.$store.state.qrStore.goods.mberId,
        nextUrl : this.$store.state.qrStore.goods.nextUrl,
        notiUrl : this.$store.state.qrStore.goods.notiUrl,
      },
      isTypingCvc: false,
      cc: {
        number: '',
        holder: '',
        exp: '',
        cvc: '',
      },
    }
  },
  methods: {
    ...mapMutations('qrStore', ['set_goods_info']),
    ...mapActions('qrStore' , [
      'fetch_qr_order_info'
    ]),
    ...mapMutations(['SET_ALERT_DIALOG']),

    // 하위 컴포넌트등에서 로딩 이 필요할때
    loadingEvent(message){
      this.overlayMassage = message;
      this.overlay = true;
    },
    // 상대방 결과 페이지로 전달 9000 - 취소 1000  승인
    nextGo( statusVal ){
      if(!statusVal){
        statusVal = '1000';
      }
      let goForm = document.createElement("form");
      document.body.appendChild(goForm);
      goForm.setAttribute('charset' , 'UTF-8');
      goForm.setAttribute('method' , 'post');
      goForm.setAttribute('action' , this.$store.state.qrStore.goods.nextUrl);

      let orderNameEl = document.createElement("input");
      orderNameEl.setAttribute('type' , 'hidden');
      orderNameEl.setAttribute('name' , 'orderName');
      orderNameEl.setAttribute('value' , this.$store.state.qrStore.goods.orderName );
      goForm.appendChild(orderNameEl);

      let exorderNoEl = document.createElement("input");
      exorderNoEl.setAttribute('type' , 'hidden');
      exorderNoEl.setAttribute('name' , 'exorderNo');
      exorderNoEl.setAttribute('value' ,this.$store.state.qrStore.goods.exorderNo);
      goForm.appendChild(exorderNoEl);

      let orderPriceEl = document.createElement("input");
      orderPriceEl.setAttribute('type' , 'hidden');
      orderPriceEl.setAttribute('name' , 'orderPrice');
      orderPriceEl.setAttribute('value' , this.$store.state.qrStore.goods.orderPrice  );
      goForm.appendChild(orderPriceEl);

      let mberIdEl = document.createElement("input");
      mberIdEl.setAttribute('type' , 'hidden');
      mberIdEl.setAttribute('name' , 'mberId');
      mberIdEl.setAttribute('value' ,  this.$store.state.qrStore.goods.mberId );
      goForm.appendChild(mberIdEl);

      // 이곳이 중요
      //////////////////////////////////////////////////////////////////////////////////////
      let statusEl = document.createElement("input");
      statusEl.setAttribute('type' , 'hidden');
      statusEl.setAttribute('name' , 'orderStatus');
      statusEl.setAttribute('value' , statusVal );
      goForm.appendChild(statusEl);
      ////////////////////////////////////////////////////////////////////////////////////

      let orderMethodEl = document.createElement("input");
      orderMethodEl.setAttribute('type' , 'hidden');
      orderMethodEl.setAttribute('name' , 'orderMethod');
      orderMethodEl.setAttribute('value' , !!this.$store.state.qrStore.payInfo ? this.$store.state.qrStore.payInfo.orderType : '' ); // 현재는 카드만 존재한다.
      goForm.appendChild(orderMethodEl);

      let orderMethod2El = document.createElement("input");
      orderMethod2El.setAttribute('type' , 'hidden');
      orderMethod2El.setAttribute('name' , 'orderMethodDetail');
      orderMethod2El.setAttribute('value' , !!this.$store.state.qrStore.payInfo ? this.$store.state.qrStore.payInfo.orderDetail : '');
      goForm.appendChild(orderMethod2El);

      let confmNoEl = document.createElement("input");
      confmNoEl.setAttribute('type' , 'hidden');
      confmNoEl.setAttribute('name' , 'confmNo');
      confmNoEl.setAttribute('value' , !!this.$store.state.qrStore.payInfo ? this.$store.state.qrStore.payInfo.confmNo : '' );
      goForm.appendChild(confmNoEl);
      goForm.submit();
    },
    clickEvent(){
      this.alertDialog = false;
    },
    payClear(statusVal){
      if(!this.$store.state.qrStore.goods.nextUrl) return;
      // 결제 노티 호출 - 서버투 서버로 발송하므로 한번 호출하고 리턴값 받을 필요없다 . 리턴값은 있으니 추후 수정할 필요있다면 리턴값 받아서 처리
      // type :100  성공 , type : 200 실패  , message : 해당 메세지
      // 그냥 노티 먼저 보내고 화면 전환하자
      if('9999'==statusVal){
        this.alertDialog = true;
        this.alertColor = 'red';
        this.alertMessage = '결제오류';
      }

      else {
        this.overlayMassage ='처리중';
        this.notiGo(statusVal)
            .then(_=>{
              this.nextGo(statusVal);
            }).finally(_=>{
          this.overlay = false;
        })
      }
    },
    // 발송후 기다리지 않는다 이유는 nextUrl 로 바로 이동한다.
    notiGo(statusVal){
      this.overlay = true;
      //this.overlayMassage = '처리중'
      this.alertMessage = '처리중 문제발생',
          this.cardDisplay = false;

      //return axios.post('https://api.swonjiny.kr/ext/notiSend' ,{
      return axios.post('https://api.uni-core.co.kr/ext/notiSend' ,{
        // 파라미터이름주의
        //return axios.post('http://localhost:8080/ext/notiSend' ,{
        orderPrice : this.$store.state.qrStore.goods.orderPrice,
        orderName : this.$store.state.qrStore.goods.orderName,
        mberId: this.$store.state.qrStore.goods.mberId,
        nextUrl : this.$store.state.qrStore.goods.nextUrl,
        notiUrl : this.$store.state.qrStore.goods.notiUrl,
        exorderNo : this.$store.state.qrStore.goods.exorderNo,
        orderType : !!this.$store.state.qrStore.payInfo ? this.$store.state.qrStore.payInfo.orderType : '',
        orderDetail : !!this.$store.state.qrStore.payInfo ? this.$store.state.qrStore.payInfo.orderDetail : '',
        confmNo : !!this.$store.state.qrStore.payInfo? this.$store.state.qrStore.payInfo.confmNo : '',
        status : statusVal,
      }).then(res =>{
        if(res.data.type!='100'){
          this.title = '다시 주문해주세요';
          this.alertMessage = '처리중 문제발생'
        }
        if(res.data.type=='100'){
          this.title = '완료되었습니다.';
          this.alertMessage = '취소 처리되어습니다.';
          this. alertColor = 'light-green darken-1'
        }
      }).catch(err=>{
        console.error(err);

        this.title = '다시 주문해주세요';
      }).finally(_=>{
        this.overlay = false;
        //	this.set_goods_info({name :'' , price :0 ,mberName : '상호' });
        //this.alertDialog = true;
      })
    }, // end notiGo
    orderNoChk(){
      if(!!this.$route.params.orderNO){
        // 주문정보 에러여부확인처리
        try {
          if( isNaN(this.$route.params.orderNO) ){
            switch (this.$route.params.orderNO) {
              case 'A002':
                throw new Error('결제정보 문제발생');
              case 'A003':
                throw new Error('요청결제정보 문제발생');
              case 'A004':
                throw new Error('주문번호누락결제');
              case 'A005':
                throw new Error('주문자사업자정보문제');
              case 'A006':
                throw new Error('주문상품명누락');
              case 'A007':
                throw new Error('주문금액오류');
              case 'A008':
                throw new Error('인증키정보 누락');
              case 'A009':
                throw new Error('키 인증정보 변조등오류');
              case 'S001':
                throw new Error('주문정보오류');
              case 'A014':
                throw new Error('이용불가 사업자요청');
              case 'L001':
                throw new Error('1회 결제금액 제한');
              case 'L002':
                throw new Error('1일 결제금액 제한');
              case 'L003':
                throw new Error('1달 결제금액 제한');
              case 'L004':
                throw new Error('1년 결제금액 제한');
              case 'C001':
                throw new Error('취소된주문');
              default:
                throw new Error('이미 결제처리된 주문');
            }
          }
        } catch (error) {
          let name = '결제 불가 주문';
          if(!!error.message) name = error.message;
          this.set_goods_info({name , price :0 ,mberName : '상호' });
          this.title = name;
          this.cardDisplay = false;
          this.overlay = false;
          this.alertDialog = true;
          this.alertColor = 'red';
          this.alertMessage = name;
          return false;
        }
        return true;
      }
    },

    fetchData(){
      this.overlay = true;
      this.overlayMassage = '조회중..'
      this.cardDisplay = true;
      this.fetch_qr_order_info(this.$route.params.orderNO)
          .then(data=>{
            // 이미 결제나 환불 취소 한경우 강제로 노티및 next 페이지로 보내버린다.

            if(data.status!='0000') {
              this.overlayMassage ='결제불가 주문 처리중';
              this.title = '결제불가 주문';
              this.payClear(data.status);
            }else {
              this.overlay = false;
              if(this.$store.state.qrStore.goods.sugiCertification==='Y') this.sugiCertification = true;
              else this.sugiCertification = false;
              this.payData.price = data.orderPrice;
              this.payData.orderName = data.orderName;
              this.payData.installment = data.installment;
              this.cardDisplay = true;
            }
          })
          .catch(err=>{
            console.error(err);
            this.cardDisplay = false;
          })
    }
  }, // END METNOD
}
</script>

<style scoped>

</style>