<template>
    <v-card outlined>
    <v-toolbar dark dense flat color="brown darken-1" class="font-weight-black">
      <v-icon color="indigo lighten-5" class="mr-3">mdi-credit-card-check</v-icon>
      <v-toolbar-title>
        결제정보
      </v-toolbar-title>
    </v-toolbar>
    <v-card outlined color="grey lighten-4">
      <v-card-text>
        <div class="font-weight-bold mt-2">
          <v-icon color="deep-orange darken-1">mdi-circle-medium</v-icon> 결제일자 : {{info.dateStr}}
        </div>
        <div class="font-weight-bold mt-2">
          <v-icon color="deep-orange darken-1" >mdi-circle-medium</v-icon> 카드이름 : {{info.name}}
        </div>
        <div class="font-weight-bold mt-2">
          <v-icon color="deep-orange darken-1">mdi-circle-medium</v-icon> 카드번호 : {{info.paymentNo}}
        </div>
        <div class="font-weight-bold mt-2">
          <v-icon color="deep-orange darken-1">mdi-circle-medium</v-icon> 승인번호 : {{info.confmNo}}
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props : {
    info : Object,
  },
}
</script>

<style>

</style>