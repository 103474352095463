import { request } from "./index";
// 외부결제 연동 관련 처리
const external = {
    // qr 코드 주문 정보확인 - 단건주문만됨
    qrOrderInfo(orderNo){
        return request('get' , `/ext/qrOrder/${orderNo}`);
    },
    // qr 수기결제 요청
    qrOrderSugi(payload){
        //debugger
        return request('post' , '/ext/qrPayRequest' ,payload);
    },
    // qr 수기결제전 취소요청
    qrOrderCancel(payload){
        return request('post' , '/ext/qrPayCancel' ,payload);
    },
}

export default external;