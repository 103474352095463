import Vue from 'vue'
import Vuex from 'vuex'

import qrStore from "./qrStore";
import smsLinkStore from "./smsLinkStore";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        // 정보 스낵바값
        infoSnackObj : {},
        alertDialog : false, // 경고 알람용
        alertMessage : '오류가 있습니다.',
        alertTitle : '안내사항',
        alertColor : 'light-green lighten-1',
        alertBtnColor : 'light-green',
        mainLoading : false,
        overLay : true, // 전체화면 조회중표시
        colorArr : [
            '#1F7087', '#952175', '#AD1457', '#7B1FA2', '#6A1B9A', '#4A148C', '#4527A0', '#311B92', '#1A237E' , '#009688'
            ,'#303F9F', '#1565C0', '#0D47A1', '#0277BD', '#01579B', '#006064', '#00838F', '#004D40', '#00695C', '#00796B'
            ,'#1B5E20', '#2E7D32', '#388E3C', '#827717', '#9E9D24', '#F57F17', '#F9A825', '#FF8F00', '#FF6F00', '#FB8C00'
            ,'#EF6C00', '#E65100', '#F4511E', '#BF360C', '#4E342E', '#3E2723', '#455A64', '#37474F', '#263238', '#212121'
            ,'#424242', '#616161', '#757575', '#000000', '#E91E63', '#F44336', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3'
        ]

    },
    getters : {
        selectedColorA(state){
            return state.colorArr[Math.floor(( Math.random() * 50 ))];
        },
        selectedColorB(state){
            return state.colorArr[Math.floor(( Math.random() * 50 ))];
        }
    },
    mutations: {
        SET_INFO_SNACKBAR(state , obj){
            state.infoSnackObj = obj;
            localStorage.setItem('infoSnack',JSON.stringify(obj));
        },
        SET_OVERLAY(state , val){
            state.overLay = val;
        },
        // 알림 다이알로그 창
        SET_ALERT_DIALOG(state, {flag ,message , title , type}){
            if(message) state.alertMessage = message;
            if(title) state.alertTitle = title;
            else {
                state.alertTitle="안내사항";
            }
            if(type==='error'){
                state.alertColor="amber lighten-1";
                state.alertBtnColor="amber";
            }else{
                state.alertColor="light-green lighten-1";
                state.alertBtnColor="light-green";
            }
            state.alertDialog = flag;
        },
        // 메뉴설정
        SET_MENU_LIST(state, list){
            state.menuList = list;
        }
    },
    actions: {
        FETCH_INFO_SNACKBAR({commit}){
            // api 호출
            let obj = {
                inHtml : '<b>서버전달 api 메세지제목</b><br/> +1 내용~~~~ ++<br/> +2 내용 !!!!!++', // 서버에서 받아 올
                reLoadFlag : false,
                snackFlag : true,
            }

            const preInfo = JSON.parse(localStorage.getItem('infoSnack'));
            if(!!preInfo)preInfo.snackFlag = true;
            if(!!preInfo && preInfo.inHtml === obj.inHtml){
                commit('SET_INFO_SNACKBAR',preInfo);
            } else commit('SET_INFO_SNACKBAR',obj);
        }

    },
    modules: {
        qrStore,
        smsLinkStore
    }
})

export default store;