<template>
  <v-dialog
    persistent 
    v-model="this.$store.state.alertDialog"
    max-width="400"
    
  >
  <v-alert
    dark
    style="opacity: 0.7"
    colored-border
    prominent
    :color="this.$store.state.alertColor"
    icon="mdi-alert-circle-outline"
    border="left"
    elevation=100
    class="ma-0"
    >
    <v-row align="center">
      <v-col>
        {{this.$store.state.alertMessage}}
      </v-col>
      <v-col class="shrink">
        <v-btn
          icon
          large
          @click="clickEvent"
        >
          <v-icon>mdi-check-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>  
  </v-alert>
   

  
    <!-- <v-card class="mx-auto" dark :color="this.$store.state.alertColor" > 
      <v-card-title class="headline">
        {{this.$store.state.alertTitle}}
      </v-card-title>
      <v-card-subtitle>
        {{this.$store.state.alertMessage}}
      </v-card-subtitle>

      <v-card-actions >

        <v-btn
          outlined
          rounded
        
          
   
          block
          @click="clickEvent"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card> -->

  </v-dialog>

</template>

<script>
import { mapMutations } from 'vuex'

export default {

  methods: {
    ...mapMutations(['SET_ALERT_DIALOG']),
    clickEvent(){
      this.SET_ALERT_DIALOG({flag : false , message :''  ,  type : 'error'});
    }
  },
}
</script>

<style>

</style>