<template>
  <v-card outlined color="grey lighten-3">
    <v-toolbar dark dense flat color="cyan darken-3" class="font-weight-black">
      <v-icon color="indigo lighten-5" class="mr-3">mdi-truck-fast-outline</v-icon>
      <v-toolbar-title>배송지정보</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mx-auto">
      <v-text-field  
        filled
        color="pink"
        placeholder="받는사람"
        v-model="info.recipient"
        hint="받으시는 분 이름을 입력하세요"
        class="v-text-new1 font-weight-black"
        label="받는사람"
        :readonly='this.disabled'
      />
      <v-text-field
        dense
        filled
        placeholder="핸드폰번호"
        label="연락처"
        v-model="info.mobilePhone"
        hint="기호 없이 숫자만 입력하세요"
        color="pink"
        class=" v-text-new1 font-weight-black"
        v-mask="'###-####-####'"
        :readonly='this.disabled'
      />
      
      <v-chip
        dark
        color="purple darken-3"
        label
        small
        class="mb-2"
        @click.stop="addrView=true"
        v-if="!this.disabled"
      >
        <v-icon small left>
          mdi-map-search-outline
        </v-icon>
        주소찾기
      </v-chip>
      
      <v-text-field
        label="주소"
        dense
        filled
        name="destInformed"
        class="v-text-new1 font-weight-black"
        v-model="info.address"
        color="pink"
        hint="주소를 직접입력하거나 찾기 버튼을 선택하세요"
        placeholder="주소를 직접입력하거나 찾기 버튼을 선택하세요"
        readonly
        v-if="this.disabled"
      />

      <v-text-field
        dense
        filled
        label="주소"
        placeholder="주소를 직접입력하거나 찾기 버튼을 선택하세요"
        v-model="info.addrInfo"
        hint="주소를 직접입력하거나 찾기 버튼을 선택하세요"
        color="pink"
        class="v-text-new1 font-weight-black"
        v-if="!this.disabled"
      />

      <v-text-field
        dense
        filled
        label="상세주소"
        placeholder="예) 101동 101호"
        v-model="info.addrDetailInfo"
        hint="추가 주소 정보를 입력하세요"
        color="pink"
        class="v-text-new1 font-weight-black"
        v-if="!this.disabled"
      />
      <v-text-field
        dense
        placeholder="우편번호"
        v-model="info.zonecode"
        hint="주소찾기를 통해서도 가능합니다."
        color="pink"
        class="v-text-new1 font-weight-black"
        filled
        label="우편번호"
        v-if=false
      />
      <v-textarea
        color="pink"
        filled
        label="요청사항"
        class="v-text-new1 font-weight-black"
        v-model='info.message'
        
        :readonly='this.disabled'
      />
    </v-card-text>
    <Address :addrView='this.addrView' @addrViewEvent='this.addrViewEvent'></Address>
  </v-card>
</template>

<script>
import Address from "@/components/sms/address.vue";

export default {
  name: "desinationInfo",
  updated() {
    this.$emit('retInfoEvent' , this.info)
  },

  components: {
    Address,
  },
  props : {
    disabled : {
      type : Boolean,
      default : true
    },
    info : {
      type : Object,
      default : function(){
        return {
        recipient : '' , // 받는사람이름
        mobilePhone : '' , // 받는사람 핸드폰번호
        addrInfo : '' , // 기본주소
        addrDetailInfo : '' , // 상세주소
        zonecode : '' , // 우편번호
        message : '', // 남기는 메세지
        address : '' // 상세보기시 사용하는 값 디비구조상 입력은 2컬럼 이지만 저장이 1개의 컬럼에 들어가므로 어쩔수 없다.
        }
      }
    }
  },
  data() {
    return {
      addrView : false,
      // retinfo : {
      //   recipient : 'qke' , // 받는사람이름
      //   mobilePhone : '' , // 받는사람 핸드폰번호
      //   addrInfo : '' , // 기본주소
      //   addrDetailInfo : '' , // 상세주소
      //   zonecode : '' , // 우편번호
      //   message : '' // 남기는 메세지
      // }
    }
  },
  methods: {
    addrViewEvent(obj){
      this.addrView= false;
      if(obj){
        this.info.addrInfo = obj.address;
        this.info.zonecode = obj.zonecode;
      }
    },
    emitEvent(){
      this.$emit('retInfoEvent' , info);
    }
  },
}
</script>
