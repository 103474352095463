<template>
  <v-footer
    dark
    color="teal darken-4"
    class="text-left"
    padless
  >
    <v-row dense>
      <v-col cols="12" xs="12" sm="8" md="8" >
        <v-card
          color="teal darken-4"
          flat
        >
          <v-card-subtitle>(주)유니코아</v-card-subtitle>
          <v-card-text class="caption">
            <div>주소 : 서울특별시 구로구 디지털로34길 43 코오롱싸이언스밸리1차 1108호 우)08378</div>
            <div>대표 : 박기훈, 상호 : 주식회사 유니코아 | 이메일 : uc@uni-core.co.kr </div>
            <div>사업자번호 : 820-81-00121 | 통신판매 신고번호 : 제 2016-서울구로-0239호 </div>
            <div>© 2020 주)유니코아. All rights reserved. </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="4" >
         <v-card
          color="teal darken-4"
          flat
        >
          <v-card-subtitle class="font-weight-black">
            <v-icon small left>mdi-phone-classic</v-icon>
            <span >1600-0174</span>
          </v-card-subtitle>
          <v-card-text class="caption">
            <div >09:30 ~ 18:30</div>
            <div>주말 공휴일 휴무</div>
            <div>개인정보 처리방침</div>
            <div>이용약관</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
   </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
