<template>
<v-container id="target">
  <v-card max-width="500" class="mx-auto" flat   >
    <SellerInfo :seller="this.SMS_PAY_RESULT_INFO.sellerInfo"
      :amount="amount"
      >
    </SellerInfo>
    <v-divider class="ma-3" ></v-divider>
    <OrderInfo :list="this.SMS_PAY_RESULT_INFO.goodsList"
               :amount="amount"
    ></OrderInfo>
    <v-divider class="ma-3"></v-divider>
    <PaymentInfo :info="this.SMS_PAY_RESULT_INFO.paymentInfo"></PaymentInfo>
    <v-divider class="ma-3"></v-divider>

    <DestinationResult :info="this.SMS_PAY_RESULT_INFO.destination ? this.SMS_PAY_RESULT_INFO.destination : {}" />
  </v-card>
  <v-container>

  </v-container >
  <v-card max-width="500" class="mx-auto" flat id="target2">
    <v-row max-width="500">
      <v-col cols="6" xs="12">
        <v-btn
          block color="secondary"
          @click="callPhone" >
          판매자연락
          <v-icon right>
            mdi-phone정
          </v-icon>
        </v-btn>
      </v-col>
       <v-col cols="6" xs="12">
        <v-btn
          block color="error"
          @click="goSlip" >
          매출전표보기
          <v-icon right>
            mdi-download
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>

</v-container>
</template>

<script>
import { mapActions,mapMutations, mapGetters } from 'vuex';
import SellerInfo from "@/components/sms/SellerInfo.vue";
import OrderInfo from "@/components/sms/OrderInfo";
import PaymentInfo from "@/components/sms/PaymentInfo.vue";
import DestinationInfo from "@/components/sms/DestinationInfo.vue";
import DestinationResult from "@/components/sms/DestinationResult.vue";
import CardResult from "../components/sms/cardResult.vue";

export default {
  name: "SmsPayResult",
  created() {
    document.title = '비즈페이 결제정보 조회';
    this.fetch_sms_payment_result(this.$route.params.id)
  },

  components : {
    SellerInfo,
    OrderInfo,
    PaymentInfo,
    DestinationInfo,
    DestinationResult,
    CardResult
  },

  computed : {
    ...mapGetters('smsLinkStore', ['SMS_PAY_RESULT_INFO']),
    amount(){
      if(this.SMS_PAY_RESULT_INFO.goodsList.length >0){
        let sum = 0;
        this.SMS_PAY_RESULT_INFO.goodsList.forEach(item => {
          //sum = sum + item.itCount * item.itPrice
          sum = sum + item.itPrice
        });
        return sum;
      }
      else return 0
    }
  },

  data() {
    return {
      cardResultFlag : false,
    }
  },

  methods : {
    ...mapActions('smsLinkStore' , ['fetch_sms_payment_result']),
    ...mapMutations(['SET_ALERT_DIALOG']),
    pageGo(){
      location.href='http://www.uni-core.co.kr/'
    },
    callPhone(){
      try {
        location.href=`tel:${this.SMS_PAY_RESULT_INFO.sellerInfo.mberMobile}`
      } catch (error) {
        this.SET_ALERT_DIALOG({flag : true , message : '판매자 연락처문제가 있습니다.' , type : 'error' });
      }
    },
    goSlip(){
      this.$router.push(`/SmsSalesSlip/${this.$route.params.id}`)
    }
  }
}
</script>
