<template>
  <v-card outlined class="mx-auto" color="grey lighten-3">
    <v-toolbar dark dense flat color="indigo darken-1" class="font-weight-black">
      <v-icon color="indigo lighten-5" class="mr-3">mdi-basket-outline</v-icon>
      <v-toolbar-title>
        판매자 정보
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>상호</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ seller.companyName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>연락처</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ seller.mberMobile | handPhoneNum }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- <v-col cols="12">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>결제금액</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ amount | currency('원')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col> -->
        <v-col cols="12" v-if="seller.smslinkMemo">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>요청메세지</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ seller.smslinkMemo }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="seller.custPhone">
          <v-card outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>구매자 연락처</v-list-item-subtitle>
                <v-list-item-title class="font-weight-black">
                  {{ seller.custPhone | handPhoneNum }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SellerInfo",
  props : {
    seller : Object,
    amount : Number
  }
}
</script>

<style scoped>

</style>