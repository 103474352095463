<template>
  <div>
    <v-app-bar
        color="teal darken-4"
        dark
    >
      <v-app-bar-nav-icon>
        <template v-slot>
          <v-img
          src='/symbol_gr.png'
          contain
          max-height="35"
          class="text-rigint"
          />
        </template>
      </v-app-bar-nav-icon>
      <div class="font-weight-black">{{title}}</div>
      <v-spacer></v-spacer>
      <div class="font-weight-bold"> 비즈페이 원격결제 서비스</div>
    </v-app-bar>
    <!-- 스켈톤 로더 최신 로딩 스타일 한번 해보자 -->
    <SkeletonLoading :loading='loading'></SkeletonLoading>
    <v-card max-width="500" flat class="mx-auto" v-if="!loading">
      <SellerInfo
        :seller="{ companyName : SMS_GOOD_INFO.companyName , mberMobile :SMS_GOOD_INFO.mberMobile, custPhone : SMS_GOOD_INFO.custPhone , smslinkMemo : SMS_GOOD_INFO.smslinkMemo }"
        :amount="SMS_GOOD_INFO.itTotalAmt"
      >
      </SellerInfo>
      <v-divider class="ma-3"/>
      <OrderInfo :list="this.goodsList" :amount="SMS_GOOD_INFO.itTotalAmt" />
      <v-divider class="ma-3"/>
      <DestinationInfo :disabled=false @retInfoEvent='destinationGet' v-if="SMS_GOOD_INFO.addrYn==='Y'" />
      <v-divider class="ma-3"/>
      <v-card outlined  color="grey lighten-3" v-if="SMS_GOOD_INFO.payFinishYn!='Y'">
        <v-toolbar dark dense flat color="amber darken-3" class="font-weight-black">
          <v-icon class="mr-3">mdi-progress-check</v-icon>
          <v-toolbar-title>이용동의</v-toolbar-title>
          <v-spacer/>
          <v-checkbox
            class="pt-5"
            type="checkbox"
            label="전체동의"
            v-model="checkbox0"
            @click="checkEvent"
          />
        </v-toolbar>

        <v-card-text>
          <v-list dense flat color="grey lighten-3">
            <v-list-item dense >
              <v-list-item-content>
                <v-checkbox
                  v-model="checkbox1"
                  color="purple darken-3"
                  type="checkbox"
                  required
                >
                  <template v-slot:label>
                    <div class="pl-2 body-2 font-weight-black">
                      비즈페이 이용약관 동의
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn dark class="font-weight-black" color="purple darken-3" @click="confirmClickEvent">
                  보기
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset />
            <v-list-item dense >
              <v-list-item-content>
                <v-checkbox
                  v-model="checkbox2"
                  color="purple darken-3"
                  type="checkbox"
                  required
                >
                  <template v-slot:label>
                    <div color="red" class="pl-2 body-2 font-weight-black">
                      개인정보활용 동의
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item-content>
            <v-list-item-action>
              <v-btn dark class="font-weight-black" color="purple darken-3" @click="infoClickEvent">
                보기
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card >
      <v-divider class="ma-3"/>
        <v-btn
          v-if="SMS_GOOD_INFO.payFinishYn!='Y'"
          block
          class="mb-5"
          color="success"
          :disabled='payBtnFlag'
          @click="paymentEvent" >
          결제하기
        </v-btn>
    </v-card>
    <ConsentForm :consentflag='this.consentflag' @confirmEvent='confirmEvent'></ConsentForm>
    <InfoForm :consentflag='this.infoflag' @confirmEvent='infoEvent'></InfoForm>
    <SugiCard
        :dialogFlag='this.sugiCardFlag'
        :installmentMonths='SMS_GOOD_INFO.installmentMonths'
        :price='SMS_GOOD_INFO.itTotalAmt'
        :sugiCertification='SMS_GOOD_INFO.sugiCertification'
        @sugiCardEvent='sugiCardEvent'></SugiCard>
    <CardResult :flag='this.cardResultFlag' @colseEvent='()=>this.cardResultFlag=false' :payInfo='this.CARD_PAYMENT_INFO'></CardResult>
  </div>
</template>

<script>
import { mapActions, mapGetters , mapMutations } from 'vuex';

import ConsentForm from "../components/sms/consentForm.vue";
import InfoForm from "../components/sms/infoForm.vue";
import SugiCard from "../components/sms/sugiCard.vue";
import CardResult from "../components/sms/cardResult.vue";
import SkeletonLoading from "../components/Loader/skeleton01.vue";
import SellerInfo from "@/components/sms/SellerInfo.vue";
import OrderInfo from "@/components/sms/OrderInfo";
import DestinationInfo from "@/components/sms/DestinationInfo";
import { urlCheck } from "@/util/filter";
export default {
  name: "SmsPayPage",
  created() {
    document.title = '비즈페이 SMS 결제';
    if(this.$route.params.id){
      this.fetch_sms_good_info(this.$route.params.id)
          .then(data=>{
                if(data) {
                  this.payRequest.id = this.$route.params.id;
                  this.payRequest.mberCode = this.SMS_GOOD_INFO.mberCode;
                  this.payRequest.totAmt = this.SMS_GOOD_INFO.itTotalAmt;
                  this.loading=false;
                  if(data.payFinishYn==='Y'){
                    this.$router.push("/external/smsPayResult/"+this.$route.params.id);
                  }
                }
                else this.loading=true;
              }
          ).catch(error=>{
            if(error.type==='SMS04'){
              this.$router.push("/external/smsPayResult/"+this.$route.params.id);
            }else this.SET_ALERT_DIALOG({flag : true , message :error.message});
          })
    }
  },

  components: {
    ConsentForm,
    SugiCard,
    CardResult,
    InfoForm,
    SkeletonLoading,
    SellerInfo,
    OrderInfo,
    DestinationInfo
  },
  computed : {
    ...mapGetters('smsLinkStore', ['SMS_GOOD_INFO','CARD_PAYMENT_INFO','SMS_PAY_RESULT_INFO']),
    goodsList(){
      const retArr = [];
      for (let index = 0; index < this.$store.state.smsLinkStore.smsGoodsInfo.nameList.length; index++) {
        let goods = {
          itName : this.$store.state.smsLinkStore.smsGoodsInfo.nameList[index],
          itAddInfo : this.$store.state.smsLinkStore.smsGoodsInfo.infoList[index],
          itCount : this.$store.state.smsLinkStore.smsGoodsInfo.countList[index],
          itPrice : this.$store.state.smsLinkStore.smsGoodsInfo.priceList[index],
          itDetailUrl : this.$store.state.smsLinkStore.smsGoodsInfo.urlList[index],
        }
        retArr.push(goods);
      }
      return retArr;
    },


    payBtnFlag(){
      if(this.checkbox1
          && this.checkbox2
          && this.payRequest.totAmt > 0){
            if(this.SMS_GOOD_INFO.addrYn==='Y' ){
              if(this.payRequest.addrInfo && this.payRequest.recipient){
                return false;
              }
            }else{
              return false
            }
          }
      return true;
    },

  },
  methods: {
    ...mapActions('smsLinkStore', ['fetch_sms_good_info' , 'request_sms_payment','fetch_card_payment_info']),
    ...mapMutations([
      'SET_ALERT_DIALOG'
    ]),
    // 배송지 정보 획득
    destinationGet(infoObj){
      this.payRequest.recipient = infoObj.recipient
      this.payRequest.mobilePhone = infoObj.mobilePhone
      this.payRequest.addrInfo = infoObj.addrInfo
      this.payRequest.addrDetailInfo = infoObj.addrDetailInfo
      this.payRequest.zonecode = infoObj.zonecode
      this.payRequest.message = infoObj.message
    },
    // 결제하기 이벤트
    sugiCardEvent(returnData){
      this.sugiCardFlag = false;
      // 카드정보 세팅
      if(returnData){
        this.loading=true;
        this.payRequest.cardNumber = returnData.cardNumber;
        this.payRequest.expiration = returnData.expiration;
        this.payRequest.installment = returnData.installment;
        this.payRequest.pidNum = returnData.pidNum;
        this.payRequest.passwd = returnData.passwd;
        if(this.payRequest.mobilePhone)this.payRequest.mobilePhone =  this.payRequest.mobilePhone.replace(/-/g,"");
        // 택배 주소 요청 Y/N
        this.payRequest.addrYn = this.SMS_GOOD_INFO.addrYn
        // 결제요청
        this.request_sms_payment(this.payRequest)
            .then(_=>{
              this.SET_ALERT_DIALOG({flag : true , message :'결제를 완료하였습니다.'});
              this.loading=true;
              this.$router.push("/external/smsPayResult/"+this.$route.params.id);
            })
            .catch(err=>{
              this.loading=false;
              this.sugiCardFlag = true;
            })
      }

    },

    checkEvent(){
      if(this.checkbox0){
        this.checkbox1 = true;
        this.checkbox2 = true;
      }else{
        this.checkbox1 = false;
        this.checkbox2 = false;
      }
    },
    detailUrlEvent(url){
      if(url){
        if(urlCheck(url) ){
          window.open(url , "_blank");
        }else {
          window.open("https://"+ url , "_blank");
        }
      }
    },
    confirmClickEvent(){
      this.consentflag = false;
      this.consentflag = true;
    },

    infoClickEvent(){
      this.infoflag = false;
      this.infoflag = true;
    },

    confirmEvent(){
      this.consentflag = false;
    },
    infoEvent(){
    this.infoflag = true;
      this.infoflag = false;
    },

    paymentEvent(){
      this.sugiCardFlag = true;
    },
    imageDown(){
      let pdfImg = {
        target: 'body',
        captureHiddenClass: 'vti__hidden',
        captureShowClass: 'vti__show',
        captureActiveClass: 'vti__active',
        fileName: '비즈페이결제정보',
        fileType: 'png',
        returnAction: 'download', // blob, base64, canvas, clipboard, newWindow
        callback: (img) => { return img } // modifies what image is returned
      }
      vue2img().image(pdfImg)
    }
  },
  data() {
    return {
      cardResultFlag : false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      title : "Biz Pay" ,
      loading : true,
      sugiCardFlag : false,
      consentflag : false, // 사용자동의
      infoflag : false, // 개인정보동의
      addrView : false,
      checkbox0 : false,
      checkbox1 : false,
      checkbox2 : false,

      // 결제요청정보
      payRequest : {
        id : '', // sms 상품 아이디
        mberCode: '' , // 판매자 멤버코드
        recipient: '' , // 배송받는사람
        mobilePhone: '' , // 배송받을사람 핸드폰번호
        addrInfo: '' , // 배송지 주소
        addrDetailInfo: '' , //배송지 상세주소
        zonecode: '' , // 배송지 우편번호
        message: '' , // 배송지용 메세지
        rciptNo: '' , // 비즈페이사용 영수증번호
        payType: 'SMSPAY' , // 결제타입
        totAmt: '' , // 전체 결제금액
        cardNumber: '' ,// 결제요청 카드번호
        expiration: '' , // 카드 유효기간
        installment: '' , // 할부기간
        pidNum: '' , // 생년 법인번호 - 수기 인증인경우
        passwd: '' , // 카드번호 - 수기 인증인경우
        cardEmail: '' , // 카드결제한 사람 이메일
        cardMobilePhone: '' ,// 카드결제한 사람 핸드폰 번호
        addrYn: '', // 택배 주소 요청 Y/N
      },
    }
  },
}
</script>

<style>
 .v-text-new1 .v-input__slot {
  background-color: white !important;
}
</style>
