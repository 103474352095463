<template>
  <v-dialog persistent v-model="flag" scrollable>

    <v-card flat class="mx-auto" color="grey lighten-3">
      <v-toolbar dark dense flat color="amber darken-3" class="font-weight-black">
        <v-btn icon>
          <v-icon>mdi-checkbox-marked-circle</v-icon>
        </v-btn>
        판매자 상품목록
        <v-spacer></v-spacer>
        <v-btn @click="addEvent(true)" text class="font-weight-black">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list
          flat
          subheader
          two-line
      >
        <v-subheader>상품들을 선택한후 추가하기를 선택하세요 </v-subheader>
        <v-list-item-group
            v-model="settings"
            multiple
            active-class=""
        >
          <v-list-item v-for="good in goods" :key="good.itId">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{good.itName}}</v-list-item-title>
                <v-list-item-subtitle>{{good.itAddInfo}} -  {{good.itPrice | currency('원')}} </v-list-item-subtitle>
              </v-list-item-content>
            </template>

          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-btn block dark color="pink" @click="addEvent(false)" >추가하기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "linkGoodsSelect",
  props : ['flag' , 'goods'],
  data() {
    return {
      settings: [],
    }
  },
  methods: {
    addEvent(flag){
      // 선택한 상품 추출 여기서 한다.

      if(flag){
        return this.$emit('goodAddEvent');
      }

      let retGoods = [];
      for (let index = 0; index < this.settings.length; index++) {
        retGoods.push(this.goods[this.settings[index]]);
      }
      this.$emit('goodAddEvent',retGoods);
    }
  },
}
</script>

<style scoped>

</style>