import external from "../api/external";

const qrStore = {
    namespaced: true,
    state : {
        goods : {
            name : '',
            price : 0,
            mberName : '',
        },
        payInfo : null,
    },
    getters : {},
    mutations : {
        set_goods_info(state , info){
            state.goods = info;
        },
        set_pay_info(state , info){
            state.payInfo = info;
        }
    },
    actions : {
        async fetch_qr_order_info({commit} , orderNo ){
            try {
                const info = await external.qrOrderInfo(orderNo)
                await commit('set_goods_info' ,info );
                return info
            } catch (error) {
                console.log('에러먼저발생');
                commit('SET_ALERT_DIALOG',{ flag : true , message :'시스템 오류' , title : '오류' , type : 'error'}, { root: true });
                throw new Error(error);
            }
        },
        async fetch_qr_pay_request({commit} , payload){
            try {
                const info = await external.qrOrderSugi(payload);
                await commit('set_pay_info' ,info );
            } catch (error) {

                //commit('SET_ALERT_DIALOG',{ flag : true , message : '결제실패 잠시후 다시 결제해주세요', title : '결제실패' , type : 'error'}, { root: true });
                throw new Error(error);
            }
        },
        // 결제전취소를 요청한경우
        async fetch_qr_pay_cancel({commit} , payload){
            try {
                await external.qrOrderCancel(payload);
                const goods ={
                    name : '취소된주문',
                    price : 0,
                    mberName : 'QR거래요청',
                };
                await commit('set_pay_info' ,goods );
            } catch (error) {
                //commit('SET_ALERT_DIALOG',{ flag : true , message : '결제실패 잠시후 다시 결제해주세요', title : '결제실패' , type : 'error'}, { root: true });
                throw new Error(error);
            }
        }
    }
}

export default qrStore;