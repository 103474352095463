<template>
  <v-container>
    <v-card max-width="500" flat class="mx-auto my-10" v-if="loading">
      <v-row >
        <v-col cols="12">
          <v-skeleton-loader
              class="ma-4"
              type="heading ,list-item, divider,list-item,divider,list-item,divider,list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
              class="ma-4"
              type="heading ,list-item, divider,list-item,divider,list-item,divider,list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
              class="ma-4"
              type="heading ,list-item-three-line , divider, list-item-three-line "
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
              class="ma-4"
              type="heading ,list-item-avatar , divider, list-item-avatar "
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "skeleton01",
  props : ['loading']
}
</script>

<style scoped>

</style>