<template>
  <v-app>
        <v-slide-y-reverse-transition>
          <router-view></router-view>
          <Footer></Footer>
        </v-slide-y-reverse-transition>
  </v-app>
</template>

<script>
import Footer from "../layout/Footer.vue";
export default {
  name: 'ExternalLayout',
  data() {
    return {

    }
  },
  components : {Footer},

  methods: {

  },
}
</script>

<style>
 .v-main{
   background-color:#E0F2F1 !important;
   padding: 35px 0px 0px !important;; 
 }
</style>