// 매출전표
<template>
 <v-container
      fill-height
    >
  <v-card
    class="mx-auto"
    max-width="900"
    min-width="500"
  >
    <v-card-text>
      <div class="text-h5 text--primary mb-2">
        신용카드 매출전표
      </div>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            카드종류
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            카드번호
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
            {{this.CARD_PAYMENT_INFO.issueCmpnyNm}}
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
            {{this.CARD_PAYMENT_INFO.cardNo}}
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            <template v-if="this.CARD_PAYMENT_INFO.cancelDt">
              취소일시
            </template>
            <template v-else>
              거래일시
            </template>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            구매자명
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
            <template v-if="this.CARD_PAYMENT_INFO.cancelDt">
             {{this.CARD_PAYMENT_INFO.cancelDt}}
            </template>
            <template v-else>
              {{this.CARD_PAYMENT_INFO.confmDt}}
            </template>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >

          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            매입사
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            승인번호
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            할부
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
             {{this.CARD_PAYMENT_INFO.issueCmpnyNm}}
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
             {{this.CARD_PAYMENT_INFO.confmNo}}
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
             {{this.CARD_PAYMENT_INFO.instlmtMonth}}
          </v-card>
        </v-col>
      </v-row>


      <div class="text-h5 text--primary my-4">
        판매자 정보
      </div>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            판매자상호
          </v-card>
        </v-col>
        <v-col  cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            대표자명
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
            text-center
          >
          {{this.CARD_PAYMENT_INFO.companyName}}
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
            text-center
          >
            {{this.CARD_PAYMENT_INFO.mberName}}
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            사업자등록번호
          </v-card>
        </v-col>
        <v-col  cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            판매자연락처
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
             {{this.CARD_PAYMENT_INFO.bizNum}}
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            min-height="32"
          >
            {{this.CARD_PAYMENT_INFO.mberMobile | handPhoneNum}}
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            판매자사업장주소
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-card
            class="pa-1"
            outlined
            tile
            min-height="32"
          >
            {{this.CARD_PAYMENT_INFO.adres}}
          </v-card>
        </v-col>
      </v-row>

      <div class="text-h5 text--primary my-4">
        결제사 정보
      </div>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            가맹점명
          </v-card>
        </v-col>
        <v-col  cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            대표자명
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
          >
            주식회사 유니코아
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
          >
            박기훈
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            가맹점번호
          </v-card>
        </v-col>
        <v-col  cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            사업자등록번호
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1  text-center"
            outlined
            tile
          >
            131350091
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1  text-center"
            outlined
            tile
          >
            820-81-00121
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            tile
            style="background-color: lightgrey;"
          >
            전화번호
          </v-card>
        </v-col>
        <v-col  cols="6">
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            팩스번호
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card
            class="pa-1  text-center"
            outlined
            tile
          >
            1600-0714
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            class="pa-1  text-center"
            outlined
            tile
          >
            02-6442-5637
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-card
            class="pa-1 text-center"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            주소
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-card
            class="pa-1"
            outlined
            tile
          >
            서울특별시 구로구 디지털로34길 43 코오롱싸이언스밸리1차 1108호
          </v-card>
        </v-col>
      </v-row>
      <div class="text-h5 text--primary my-4">
        금액
      </div>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card
            class="py-1 pl-3 text-left"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            금액
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card
            class="py-1 pr-3 text-right"
            outlined
            tile
          >
            {{this.CARD_PAYMENT_INFO.splpc | currency('원') }}
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card
            class="py-1 pl-3 text-left"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            봉사료
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card
            class="py-1 pr-3 text-right"
            outlined
            tile
          >
            0 원
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card
            class="py-1 pl-3 text-left"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            부가세
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card
            class="py-1 pr-3 text-right"
            outlined
            tile
          >
            {{this.CARD_PAYMENT_INFO.vat | currency('원') }}
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card
            class="py-1 pl-3 text-left"
            outlined
            style="background-color: lightgrey;"
            tile
          >
            합계
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card
            class="py-1 pr-3 text-right"
            outlined
            tile
          >
           {{this.CARD_PAYMENT_INFO.splpc +this.CARD_PAYMENT_INFO.vat | currency('원') }}
          </v-card>
        </v-col>

      </v-row>

    </v-card-text>
    <v-card-actions class="px-3" id="yogiyo">
      <v-btn block color="gray" dark @click='imageDown(CARD_PAYMENT_INFO.confmNo)'>다운로드</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
// fetch_card_payment_info
import Vue2Img from 'vue-2-img'
import 'vue-2-img/dist/vue-2-img.css'
import { mapActions, mapGetters , mapMutations } from 'vuex';
export default {
  name: "SmsSalesSlip",
  mounted() {
    if(this.$route.params.id){
      this.fetch_card_payment_info(this.$route.params.id);
    }
  },

  computed : {
    ...mapGetters('smsLinkStore', ['CARD_PAYMENT_INFO'])
  } ,

  methods: {
    ...mapActions('smsLinkStore', ['fetch_card_payment_info']),
    imageDown(name){
      document.querySelector('#yogiyo').style.display="none";

      const pdfImg = {
        target: 'body',
        captureHiddenClass: 'v-footer',
        captureShowClass: 'vti__show',
        captureActiveClass: 'vti__active',
        fileName: `신용카드매출전표_${name}`,
        fileType: 'png',
        returnAction: 'download', // blob, base64, canvas, clipboard, newWindow
        callback: (img) => { return img } // modifies what image is returned
      }
      vue2img().image(pdfImg)
      .then(_=>{
        document.querySelector('#yogiyo').style.display="block"
      });
    }
  },
}
</script>

<style>

</style>
