<template>
  <v-dialog persistent v-model="addrView" scrollable>
    <v-card>
      <v-toolbar dark dense flat color="amber darken-3" class="font-weight-black">
        주소찾기
        <v-spacer></v-spacer>
        <v-btn @click="addEvent(null)" text class="font-weight-black">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <VueDaumPostcode @complete="addEvent" :q="this.search" :hide-map-btn='true' :hide-eng-btn='true' />
    </v-card>
  </v-dialog>
</template>

<script>
import { VueDaumPostcode } from "vue-daum-postcode"
export default {
  name: "address",
  mounted() {
    this.search = '';
  },
  props : ['addrView'],
  data() {
    return {
      search : '',

    }
  },
  components: {
    VueDaumPostcode,
  },
  methods: {
    addEvent(val){
      let address = '';
      let zonecode = '';
      let info = null;
      if(val){
        if(val.userSelectedType==='R'){
          address= val.roadAddress;
        }
        if(val.userSelectedType==='J'){
          address= val.jibunAddress;
        }
        zonecode = val.zonecode;
        info = {address , zonecode}
      }

      this.search="";
      this.$emit('addrViewEvent', info);
    }
  },
}
</script>

<style scoped>

</style>