<template>
  <div>
    <v-app-bar
        color="teal darken-4"
        dark
    >
    <v-app-bar-nav-icon>
      <template v-slot>
        <v-img
        src='/symbol_gr.png'
        contain
        max-height="35"
        class="text-rigint"
        />
      </template>
    </v-app-bar-nav-icon>
      <div class="font-weight-black">{{title}}</div>
      <v-spacer></v-spacer>
      <div class="font-weight-bold"> 비즈페이 원격결제 서비스</div>
    </v-app-bar>
    <SkeletonLoading :loading='loading'></SkeletonLoading>

    <v-card max-width="500" flat class="mx-auto" v-if="!loading">
      <SellerInfo :seller="sellerInfoObject" :amount="this.payRequest.totAmt" ></SellerInfo>
      <v-divider class="ma-3"/>
      <v-card outlined class="my-3" color="grey lighten-3">
        <v-toolbar dark dense flat color="pink darken-1" class="font-weight-black" >
          <v-icon color="indigo lighten-5" class="mr-3">mdi-gift-open-outline</v-icon>
          <v-toolbar-title>
            상품 상세
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              v-if="this.LINK_GOOD_INFO[0].allItemYn==='Y'"
              outlined
              color="white"
              @click="linkGoodsFlag= !linkGoodsFlag"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            상품선택하기
          </v-btn>
        </v-toolbar>
        <v-card outlined class="mt-2" color="grey lighten-3"  v-for="goods in this.checkGoodsList" :key="goods.itId" >
          <v-card-title class="font-weight-black text-h5">{{goods.itName}}</v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text>
            <div class="font-weight-black text-h6">{{goods.itAddInfo}}</div>
            <v-row no-gutters class="mt-2">
              <v-col cols="4" dense class="font-weight-black">
                <v-icon color="pink lighten-1">mdi-circle-medium</v-icon>수량 : {{goods.itemCount}}
              </v-col>
              <v-col cols="8">
                <v-slider
                    dense
                    v-model="goods.itemCount"
                    track-color="grey"
                    always-dirty
                    min="0"
                    max="10"
                    hide-details
                    readonly
                    @change="countMove(goods)"
                >
                  <template v-slot:prepend>
                    <v-icon
                        color="red"
                        @click.stop="countMove(goods, '-')"
                    >
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon
                        color="green"
                        @click.stop="countMove(goods , '+')"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <div class="mt-2 font-weight-black">
              <v-icon color="deep-orange darken-1" >mdi-circle-medium</v-icon> 결제금액 : {{goods.itemAmt|  currency('원')}}
            </div>
          </v-card-text >

          <v-card-actions >
            <v-btn block outlined color="amber darken-4" class="font-weight-black" @click="detailUrlEvent(goods.itDetailUrl)">
              상품정보 확인 <v-icon class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-divider class="mx-5"></v-divider>

          <v-card-title class="font-weight-black text-h6"><v-icon color="deep-orange darken-1" >mdi-circle-medium</v-icon>총결제금액 :{{payRequest.totAmt | currency('원')}} </v-card-title>
      </v-card>
      <v-divider class="ma-3"/>
      <DestinationInfo :disabled=false @retInfoEvent='destinationGet' v-if="this.LINK_GOOD_INFO[0].addrYn==='Y'" />
      <v-divider class="ma-3"/>
      <v-card outlined class="mt-2" color="grey lighten-3">
        <v-toolbar dark dense flat color="amber darken-3" class="font-weight-black">
          <v-icon class="mr-3">mdi-progress-check</v-icon>
          <v-toolbar-title>
            이용동의
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-checkbox
              class="pt-5"
              type="checkbox"
              label="전체동의"
              v-model="checkbox0"
              @click="checkEvent"
          ></v-checkbox>
        </v-toolbar>

        <v-card-text>
          <v-list dense flat color="grey lighten-3">
            <v-list-item dense >
              <v-list-item-content>
                <v-checkbox
                    v-model="checkbox1"
                    color="purple darken-3"
                    type="checkbox"
                    required
                >
                  <template v-slot:label>
                    <div class="pl-2 body-2 font-weight-black">
                      비즈페이 이용약관 동의
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn dark class="font-weight-black" color="purple darken-3" @click="confirmClickEvent">
                  보기
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item dense >
              <v-list-item-content>
                <v-checkbox
                    v-model="checkbox2"
                    color="purple darken-3"
                    type="checkbox"
                    required
                >
                  <template v-slot:label>
                    <div class="pl-2 body-2 font-weight-black">
                      개인정보활용 동의
                    </div>
                  </template>
                </v-checkbox>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn dark class="font-weight-black" color="purple darken-3" @click="infoClickEvent">
                  보기
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-divider class="ma-3"/>
      <v-btn block class="mb-5" color="success" :disabled='payBtnFlag' @click="paymentEvent" >결제하기</v-btn>
    </v-card>

    <ConsentForm :consentflag='this.consentflag' @confirmEvent='confirmEvent'></ConsentForm>
    <InfoForm :consentflag='this.infoflag' @confirmEvent='infoEvent'></InfoForm>


    <SugiCard
        :dialogFlag='this.sugiCardFlag'
        :installmentMonths='this.installmentMonths'
        :price='this.payRequest.totAmt'
        :sugiCertification='this.sugiCertification'
        @sugiCardEvent='sugiCardEvent'>
    </SugiCard>
    <LinkGoodsSelect :flag='this.linkGoodsFlag' @goodAddEvent='goodAddEvent' :goods='this.LINK_GOOD_INFO' ></LinkGoodsSelect>
    <CardResult :flag='this.cardResultFlag' @colseEvent='()=>this.cardResultFlag=false' :payInfo='this.CARD_PAYMENT_INFO'></CardResult>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Address from "../components/sms/address"
import ConsentForm from "../components/sms/consentForm.vue";
import InfoForm from "../components/sms/infoForm.vue";
import SugiCard from "../components/sms/sugiCard.vue";
import LinkGoodsSelect from "../components/sms/linkGoodsSelect.vue";
import CardResult from "../components/sms/cardResult.vue";
import SellerInfo from "../components/sms/SellerInfo.vue";
import SkeletonLoading from "../components/Loader/skeleton02.vue";
import DestinationInfo from "@/components/sms/DestinationInfo";
import { urlCheck } from "@/util/filter";

export default {
  name: "LinkPay",
  mounted() {
    document.title = '비즈페이 LINK 결제';
    if(this.$route.params.id){
      this.fetch_link_goods_info(this.$route.params.id)
          .then(list=>{
            this.checkGoodsList = [];
            if(list.length >0){
              this.loading=false;
              this.installmentMonths = list[0].installmentMonths
              this.sugiCertification = list[0].sugiCertification
              if(list[0].allItemYn === 'N'){

                this.checkGoodsList.push(list[0])
              }else{
                //this.checkGoodsList= list;
              }
              this.sumPaymentPrice();
            }
            else {
              this.loading=true;
            }
          });
    }
  },

  components: {
    Address,
    ConsentForm,
    SugiCard,
    LinkGoodsSelect,
    CardResult,
    SkeletonLoading,
    InfoForm,
    SellerInfo, // 판매자 정보
    DestinationInfo
  },

  computed : {
    ...mapGetters('smsLinkStore', ['LINK_GOOD_INFO','CARD_PAYMENT_INFO']),

    payBtnFlag(){
      if(this.checkbox1
          && this.checkbox2
          && this.payRequest.totAmt > 0){
            if(this.LINK_GOOD_INFO[0].addrYn==='Y' ){
              if(this.payRequest.addrInfo && this.payRequest.recipient){
                return false;
              }
            }else{
              return false
            }
          }
      return true;
    },

    sellerInfoObject(){
      return {
        companyName : this.LINK_GOOD_INFO[0].companyName,
        mberMobile : this.LINK_GOOD_INFO[0].mberMobile,
        custPhone: this.LINK_GOOD_INFO[0].custPhone,
        amount :0,
      }
    }
  },

  methods: {
    ...mapActions('smsLinkStore',
        ['fetch_link_goods_info'
          ,'request_link_payment'
          ,'fetch_card_payment_info']),

    // 배송지 정보 획득
    destinationGet(infoObj){
      this.payRequest.recipient = infoObj.recipient
      this.payRequest.mobilePhone = infoObj.mobilePhone
      this.payRequest.addrInfo = infoObj.addrInfo
      this.payRequest.addrDetailInfo = infoObj.addrDetailInfo
      this.payRequest.zonecode = infoObj.zonecode
      this.payRequest.message = infoObj.message
    },
    // 결제하기 이벤트
    goodAddEvent(retGoods){
      this.linkGoodsFlag =false;
      if(!retGoods) return;

      if(retGoods.length >0){
        this.checkGoodsList = retGoods;
      }
      this.sumPaymentPrice();
    },
    async sugiCardEvent(returnData){
      this.sugiCardFlag=false;

      if(!returnData){
        return;
      }
      this.loading=true;
      // sms 상품및 주문정보 초기세팅정보 생성
      let smsInfo = {
        mberCode : this.LINK_GOOD_INFO[0].mberCode,
        itId : '',
        itName : '',
        count : 0,
        itPrice : '' ,
        itAddInfo : '',
        itDetailUrl : '',
        totalCount : 0, // allitem 때 바꾼다 지금은 그대로
        totalAmt : this.payRequest.totAmt,
        payType : 'LINKPAY', // 쿼리문에 강제로 입력되지만 나중에 바꿀수 있도록 여기서도 확보
        smsLinkId : 0
      };

      // 디비 저장용 데이터로 변경하기
      if(this.checkGoodsList[0].allItemYn === 'Y'){
        smsInfo.payType = 'ALLITEM'
      }else {
        smsInfo.payType = 'LINKPAY'
      }
      this.payRequest.payType = smsInfo.payType;


      for (let index = 0; index < this.checkGoodsList.length; index++) {
        smsInfo.itId += this.checkGoodsList[index].itId;
        smsInfo.itName += this.checkGoodsList[index].itName;
        smsInfo.itPrice += this.checkGoodsList[index].itPrice*this.checkGoodsList[index].itemCount;
        smsInfo.itAddInfo += this.checkGoodsList[index].itAddInfo;
        smsInfo.itDetailUrl += this.checkGoodsList[index].itDetailUrl;
        smsInfo.count += this.checkGoodsList[index].itemCount;

        if(index != this.checkGoodsList.length-1 ){
          smsInfo.itId +="#";
          smsInfo.itName +="#";
          smsInfo.itPrice +="#";
          smsInfo.itAddInfo +="#";
          smsInfo.itDetailUrl +="#";
          smsInfo.count +="#";
        }
        smsInfo.totalCount = this.checkGoodsList.length
      }

      // smsLinkId 는 store 에서 처리한다.
      this.payRequest.mberCode = this.LINK_GOOD_INFO[0].mberCode;
      this.payRequest.cardNumber = returnData.cardNumber;
      this.payRequest.expiration = returnData.expiration;
      this.payRequest.installment = returnData.installment;
      this.payRequest.pidNum = returnData.pidNum;
      this.payRequest.passwd = returnData.passwd;
      if(this.payRequest.mobilePhone)this.payRequest.mobilePhone =  this.payRequest.mobilePhone.replace(/-/g,"");

      // 택배 주소 요청 Y/N
      this.payRequest.addrYn = this.LINK_GOOD_INFO[0].addrYn
      let smsid = await this.request_link_payment({smsInfo, linkPayment: this.payRequest});

      if(smsid){
        await this.fetch_card_payment_info(smsid);
        this.$router.push("/external/smsPayResult/"+smsid);
        //this.cardResultFlag = true;
      }else {
        this.sugiCardFlag = true;
        this.loading = false;
      }

    },
    paymentEvent(){
      this.sugiCardFlag = true;
    },
    countMove(item , type){
      if(type==='+'){
        if(item.itemCount<10) item.itemCount++
      }
      else if(type==='-'){
        if(item.itemCount>0) item.itemCount--;
      }
      item.itemAmt = item.itPrice * item.itemCount;
      this.sumPaymentPrice();
    },
    //computed 로 처리하는 자동 합산이 작동안하는 케이스 생겨서 작성함
    sumPaymentPrice(){
      this.payRequest.totAmt = 0;
      this.payRequest.count = 0;
      for (let index = 0; index < this.checkGoodsList.length; index++) {
        this.payRequest.totAmt += this.checkGoodsList[index].itemAmt;
        this.payRequest.count += this.checkGoodsList[index].itemCount;
      }
    },

    confirmClickEvent(){
      this.consentflag = false;
      this.consentflag = true;
    },

    infoClickEvent(){
      this.infoflag = false;
      this.infoflag = true;
    },

    infoEvent(){

      this.infoflag = true;
      this.infoflag = false;

    },
    confirmEvent(){

      this.consentflag = false;
    },

    checkEvent(){
      if(this.checkbox0){
        this.checkbox1 = true;
        this.checkbox2 = true;
      }else{
        this.checkbox1 = false;
        this.checkbox2 = false;
      }
    },

    detailUrlEvent(url){
      if(url){
        if(urlCheck(url) ){
          window.open(url , "_blank");
        }else {
          window.open("https://"+ url , "_blank");
        }
      }
    },

    imageDown(){
      let pdfImg = {
        target: 'body',
        captureHiddenClass: 'vti__hidden',
        captureShowClass: 'vti__show',
        captureActiveClass: 'vti__active',
        fileName: '비즈페이결제내역',
        fileType: 'png',
        returnAction: 'download', // blob, base64, canvas, clipboard, newWindow
        callback: (img) => { return img } // modifies what image is returned
      }
      vue2img().image(pdfImg)
    }
  },
  data() {
    return {
      infoflag : false, // 개인정보동의
      cardResultFlag : false,
      linkGoodsFlag : false,
      checkGoodsList : [], // 선택한 상품만 화면에 보인다.
      installmentMonths : 0,
      sugiCertification : '',
      checkbox0 : false,
      checkbox1 : false,
      checkbox2 : false,
      sugiCardFlag : false,
      loading : true,
      goodsCount : 1,
      consentflag : false,
      addrView : false,
      title : "Biz Pay" ,

      // 결제요청정보
      payRequest : {
        id : '',
        tId : '' , // 상품아아디
        mberCode: '' , // 판매자 멤버코드
        recipient: '' , // 배송받는사람
        mobilePhone: '' , // 배송받을사람 핸드폰번호
        addrInfo: '' , // 배송지 주소
        addrDetailInfo: '' , //배송지 상세주소
        zonecode: '' , // 배송지 우편번호
        message: '' , // 배송지용 메세지
        step: '' , // 기존것에 있던 단계 ! 나중에 처리하자
        rciptNo: '' , // 비즈페이사용 영수증번호
        payType: '' , // 결제타입
        count : 1 , // 전체 결래갯수
        totAmt: 0 , // 전체 결제금액
        cardNumber: '' ,// 결제요청 카드번호
        expiration: '' , // 카드 유효기간
        installment: '' , // 할부기간
        pidNum: '' , // 생년 법인번호 - 수기 인증인경우
        passwd: '' , // 카드번호 - 수기 인증인경우
        cardEmail: '' , // 카드결제한 사람 이메일
        cardMobilePhone: '' ,// 카드결제한 사람 핸드폰 번호
        addrYn: '', // 택배 주소 요청 Y/N
      },
    }
  },
}
</script>

<style scoped>

</style>
